import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Navbar from '../../Header/Navbar';
import Footer from '../../Footer/Footer';

const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(90deg, #3498db, #2980b9, #3498db);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 5s ease infinite, ${fadeIn} 1s ease-out;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 50px;
    height: 3px;
    background-color: #3498db;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100px;
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const AdminDashboard = () => {
  
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem('admin-token');
    if (!token) {
      navigate("/admin-login");
    }
  }, []);
  return (
    <>
      <Navbar />
      <DashboardContainer>
        <Title>Admin Dashboard</Title>
        <ButtonGrid>
          <StyledLink to="/service-table">
            <Button>Service</Button>
          </StyledLink>
          <StyledLink to="/user-table">
            <Button>Users</Button>
          </StyledLink>
        </ButtonGrid>
      </DashboardContainer>
      <Footer />
    </>
  );
};

export default AdminDashboard;