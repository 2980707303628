import React, { useState, useRef, useEffect } from 'react';
import './SpecificServices2.css';
import ImageBefore from './../../images/jewelry1-before.jpeg';
import ImageAfter from './../../images/jewelry1-after.jpeg';

const SpecificServices2 = ({title,description,afterImage,beforeImage}) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (isDragging && containerRef.current) {
      const touch = e.touches[0];
      const rect = containerRef.current.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleMouseUpGlobal = () => {
      setIsDragging(false);
    };
    document.addEventListener('mouseup', handleMouseUpGlobal);
    document.addEventListener('mouseleave', handleMouseUpGlobal);
    return () => {
      document.removeEventListener('mouseup', handleMouseUpGlobal);
      document.removeEventListener('mouseleave', handleMouseUpGlobal);
    };
  }, []);

  return (
    <div className="ss2-container">
      <div className="ss2-content">
        <div className="ss2-image-slider" 
          ref={containerRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <img src={beforeImage} alt="Before" className="ss2-image ss2-before" />
          <img 
            src={afterImage} 
            alt="After" 
            className="ss2-image ss2-after" 
            style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
          />
          <div className="ss2-slider" style={{ left: `${sliderPosition}%` }}>
            <div className="ss2-slider-button">
              <div className="ss2-slider-arrow ss2-left-arrow"></div>
              <div className="ss2-slider-arrow ss2-right-arrow"></div>
            </div>
          </div>
          <span className={`ss2-label ss2-before-label ${sliderPosition <= 10 ? 'ss2-hidden' : ''}`}>Before</span>
          <span className={`ss2-label ss2-after-label ${sliderPosition >= 90 ? 'ss2-hidden' : ''}`}>After</span>
        </div>
      </div>
      <div className="ss2-text-content">
        <h2>{title}</h2>
        <p>{description}</p>
        <ul>
          <li>Adding natural shadow</li>
          <li>Adding reflection shadow or a mirror effect.</li>
          <li>Adding drop shadow or 3D effect</li>
        </ul>
        <a href="#pricing" className="ss2-view-pricing">View Pricing</a>
        <div className="ss2-buttons">
          <button className="ss2-button">Get A Quote</button>
          <button className="ss2-button">Get Free Trial</button>
        </div>
      </div>
    </div>
  );
};

export default SpecificServices2;