// SignupForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
import LoginHeader from '../../components/LoginForm/LoginHeader';
import Footer from '../../components/Footer/Footer';

export default function Signup() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: ""
  });
  const [otp, setOTP] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showOTPInput, setShowOTPInput] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const submitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post("http://15.206.148.121:8080/api/users/signup", formData);
      if (response.data) {
        console.log(response.data);
        localStorage.setItem('token', response.data.token);
        setShowOTPInput(true);
      }
    } catch (error) {
      console.log(error);
      setError("Signup failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const submitOTP = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post("http://15.206.148.121:8080/api/users/verify-email", {
        email: formData.email,
        otp
      });
      if (response.data) {
        console.log(response.data);
        // Redirect to login page after successful verification
        navigate('/login');
      }
    } catch (error) {
      console.log(error);
      setError("OTP verification failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleLogin = (e) => {
    e.preventDefault();
    navigate('/login');
  }

  return (
    <>
      <LoginHeader/>
      <div className="signup-container">
        <div className="signup-card">
          <h2 className="signup-title">Create an Account</h2>
          <p className="signup-subtitle">Join us today and start your journey</p>
          {!showOTPInput ? (
            <form onSubmit={submitForm} className="signup-form">
              <div className="form-group">
                <input
                  id="username"
                  placeholder="Enter your username"
                  name="username"
                  type="text"
                  value={formData.username}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="username">Username</label>
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  id="password"
                  placeholder="Enter your password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  required
                />
                <label htmlFor="password">Password</label>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit" className="signup-button" disabled={isLoading}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </button>
            </form>
          ) : (
            <form onSubmit={submitOTP} className="signup-form">
              <div className="form-group">
                <input
                  id="otp"
                  placeholder="Enter OTP"
                  type="text"
                  value={otp}
                  onChange={(e) => setOTP(e.target.value)}
                  required
                />
                <label htmlFor="otp">OTP</label>
              </div>
              {error && <div className="error-message">{error}</div>}
              <button type="submit" className="signup-button" disabled={isLoading}>
                {isLoading ? 'Verifying...' : 'Verify OTP'}
              </button>
            </form>
          )}
          <div className="signup-footer">
            <p className="login-prompt">Already have an account? <a href="#" onClick={handleLogin}>Log In</a></p>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
}