import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import './MeetHappyClient.css';

const MeetHappyClient = () => {
  const testimonials = [
    {
      text: "Exceptional service! My product photos look stunning and professional. Highly recommend!",
      author: "John D",
      position: "E-commerce, Product Seller",
    },
    {
      text: "Amazing attention to detail. The jewelry retouching made my pieces shine. Fantastic job!",
      author: "Michael S.",
      position: "Marketing Manager",
    },
    {
      text: "“Outstanding work on my fashion photos. The retouching was flawless. Highly satisfied!",
      author: "David K.",
      position: "Freelance Photographer",
    },
    {
      text: "“Fast turnaround and top-notch quality. Perfect for my e-commerce needs. Will use again!",
      author: "Emily R.",
      position: "E-commerce, Product Seller",
    },
    {
      text: "“Great experience! The team was responsive and delivered exactly what I needed. Five stars!",
      author:  "Sarah L",
      position: "Freelance Photographer",
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  const currentTestimonial = testimonials[currentIndex];

  return (
    <div className="meet-happy-clients">
      <h2 className="client-title">Meet Our Happy Clients</h2>
      <p className="section-subtitle">
        Over <span className="highlight">15,000 photo editing clients</span> all over the world, some of them speak for us!
      </p>
      <div className="testimonial-carousel">
      <button className="carousel-button prev" onClick={prevTestimonial} aria-label="Previous testimonial">&lt;</button>
        <div className="testimonial">
          <div className="avatar">
            {[...Array(5)].map((_, index) => (
              <FontAwesomeIcon key={index} icon={faStar} className="star-icon" />
            ))}
          </div>
          <p className="testimonial-text">{currentTestimonial.text}</p>
          <p className="author-name">{currentTestimonial.author}</p>
          <p className="author-position">{currentTestimonial.position}</p>
        </div>
        <button className="carousel-button next" onClick={nextTestimonial} aria-label="Next testimonial">&gt;</button>
      </div>
    </div>
  );
};

export default MeetHappyClient;