//Footer.js
import React, { useState } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faGlobe, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faSkype, faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo from './../../images/logo.png';
import { Link, useLocation } from 'react-router-dom';
import { scrollToSection } from '../../utils/scrollutils';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [language, setLanguage] = useState('English');
  const [currency, setCurrency] = useState('USD');

  const handleNavClick = (e, sectionId) => {
    e.preventDefault();
    if (location.pathname === '/') {
      scrollToSection(sectionId);
    } else {
      navigate('/', { state: { scrollTo: sectionId } });
    }
  }
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src={logo} alt="Product Editing Logo" className="footer-logo" />
          <p className="footer-tagline">People with Excellence</p>
          <p className="footer-description">
            Lorem ipsum dolor sit amet, consect etuer adipiscing elit, sed diam nonummy nibh euismod aliquot
          </p>
          <div className="footer-social">
            <h3>FOLLOW US</h3>
            <div className="social-icons">
              <a href="#" className="social-icon facebook"><FontAwesomeIcon icon={faFacebookF} /></a>
              <a href="#" className="social-icon twitter"><FontAwesomeIcon icon={faTwitter} /></a>
              <a href="#" className="social-icon instagram"><FontAwesomeIcon icon={faInstagram} /></a>
              <a href="#" className="social-icon youtube"><FontAwesomeIcon icon={faYoutube} /></a>
            </div>
          </div>
        </div>
        
        <div className="footer-links">
          <h3>QUICK LINKS</h3>
          <ul>
            <li>
                <Link to="/" onClick={(e) => handleNavClick(e, 'home')}>Home</Link>
            </li>
            <li>
                <Link to="/services" onClick={(e) => handleNavClick(e, 'services')} className="has-dropdown">Services</Link>
            </li>
            <li>
                <Link to="/gallery" onClick={(e) => handleNavClick(e, 'gallery')}>Gallery</Link>
            </li>
            <li>
                <Link to="/contact" onClick={(e) => handleNavClick(e, 'contact')}>Contact Us</Link>
            </li>
            <li>
                <Link to="/payment-section" onClick={(e) => handleNavClick(e, 'payment')}>Payment</Link>
            </li>
          </ul>
        </div>
        
        <div className="footer-contact">
          <h3>CONTACT INFO</h3>
          <p><FontAwesomeIcon icon={faPhone} /> +91 7742555547</p>
          <p><FontAwesomeIcon icon={faSkype} /> rastogi.kanchi12</p>
          <p><FontAwesomeIcon icon={faEnvelope} /> rastogi.kanchi12@gmail.com</p>
          <address>
            Kanchi Rastogi<br />
            742 HBU Extension<br />
            Pushkar Road, Ajmer<br />
            Rajasthan - 305004 India.
          </address>
        </div>

        <div className="footer-options">
          <div className="option-item">
            <FontAwesomeIcon icon={faGlobe} />
            <select value={language} onChange={(e) => setLanguage(e.target.value)}>
              <option value="English">English</option>
              <option value="Spanish">Español</option>
              <option value="French">Français</option>
              <option value="German">Deutsch</option>
            </select>
          </div>
          <div className="option-item2" >
            <FontAwesomeIcon icon={faDollarSign} />
            <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
              <option value="USD">USD</option>
              <option value="EUR">EUR</option>
              <option value="GBP">GBP</option>
              <option value="JPY">JPY</option>
            </select>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © Product Editing Services 2019. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default Footer;