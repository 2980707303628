import React, { useEffect, useState } from 'react'
import ServicesBanner from '../../components/ServicesBanner/ServicesBanner'
import SpecificServices from '../../components/SpecificServices/SpecificServices'
import SpecificServices2 from '../../components/SpecificServices2/SpecificServices2'
import Navbar from '../../components/Header/Navbar'
import Footer from '../../components/Footer/Footer'
// import PaymentSection from '../../components/PaymentSection/PaymentSection'
import FreeTrialBanner from '../../components/FreeTrialBanner/FreeTrialBanner'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useTheme } from 'styled-components'

export default function SpecificServicesPage() {
  const [service,setService] =useState([]);
  const { id } = useParams();
  const [loading,setLoading] = useState(true);
  const [error,setError] = useState(false);
  // console.log(id)
  useEffect(()=>{
           const fetchSubServices=async () => {
              try {
                const response = await axios(` http://15.206.148.121:8080/api/services/${id}`);
                if(response.data){
                  // console.log(response.data);
                  setService(response.data.service);
                  setLoading(false);
                  
                }
              } catch (error) {
                console.log(error);
                setError(true);
              }
           }
           fetchSubServices();
          },[])
          if(loading) return <div>Loading...</div>
          if(error) return <div>error fetching data....</div>
  const evenIndexedServices = service.subServices.filter((_, index) => index % 2 === 0);
  const oddIndexedServices = service.subServices.filter((_, index) => index % 2 !== 0);
  return (
    <div>
      <Navbar/>
      <ServicesBanner title={service.name} image={` http://15.206.148.121:8080/${service.afterImage}`} description={service.description}/>
      {service.subServices.map((subservice,index)=>{
         return index%2 ?  <SpecificServices title={subservice.name} description={subservice.description} afterImage={` http://15.206.148.121:8080/${subservice.afterImage}`} beforeImage={` http://15.206.148.121:8080/${subservice.beforeImage}`}/>:
        <SpecificServices2 title={subservice.name} description={subservice.description} afterImage={` http://15.206.148.121:8080/${subservice.afterImage}`} beforeImage={` http://15.206.148.121:8080/${subservice.beforeImage}`}/>
      })}
      <FreeTrialBanner/>
      <Footer/>
    </div>
  )
}
