import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import './ClientJobDashboard.css'

const ClientJobDashboard = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get('http://15.206.148.121:8080/api/jobs/client', {
                    headers: { 'Authorization': `${token}` }
                });
                console.log('Fetched jobs:', response.data.jobs);
                setJobs(response.data.jobs);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching jobs:', err);
                setError('Failed to fetch jobs: ' + err.message);
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    const renderJobStatus = (job) => {
        switch (job.status) {
            case 'PENDING':
                return 'Pending';
            case 'IN_PROGRESS':
                return `In Progress (${job.progress}%)`;
            case 'COMPLETED':
                return 'Completed';
            case 'CANCELLED':
                return 'Cancelled';
            default:
                return 'Unknown';
        }
    };

    const renderPaymentStatus = (job) => {
        if (job.payment && job.payment.status) {
            switch (job.payment.status) {
                case 'PAID':
                    return <span className="cjd-text-success">Paid</span>;
                case 'PENDING':
                    return <span className="cjd-text-warning">Payment Pending</span>;
                case 'FAILED':
                    return <span className="cjd-text-danger">Payment Failed</span>;
                default:
                    return <span className="cjd-text-secondary">Unknown</span>;
            }
        } else {
            return (
                <Link to={`/payment/${job.id}`} className="cjd-btn cjd-btn-primary">
                    Pay Now
                </Link>
            );
        }
    };

    const renderJobCard = (job) => (
        <div key={job.id} className="cjd-job-card">
            <div className="cjd-card-body">
                <h3 className="cjd-card-title">Job #{job.id}</h3>
                <p className="cjd-card-text">
                    <span>Status:</span>
                    <span>{renderJobStatus(job)}</span>
                </p>
                <p className="cjd-card-text">
                    <span>Progress:</span>
                    <span>{job.progress}%</span>
                </p>
                <div className="cjd-progress-bar">
                    <div className="cjd-progress-bar-fill" style={{width: `${job.progress}%`}}></div>
                </div>
                <p className="cjd-card-text">
                    <span>Payment Status:</span>
                    <span>{renderPaymentStatus(job)}</span>
                </p>
                {job.subServices && job.subServices.length > 0 && (
                    <p className="cjd-card-text">
                        <span>Service:</span>
                        <span>{job.subServices[0].name}</span>
                    </p>
                )}
                <p className="cjd-card-text">
                    <span>Total Price:</span>
                    <span>{job.totalPrice} {job.currency}</span>
                </p>
                {job.editorDropboxLink && (
                    <a href={job.editorDropboxLink} target="_blank" rel="noopener noreferrer" className="cjd-btn cjd-btn-secondary">
                        View Work
                    </a>
                )}
            </div>
        </div>
    );

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    console.log('Jobs state before rendering:', jobs);

    return (
        <>
            <Navbar />
            <div className="cjd-client-job-dashboard">
                <h1>Your Jobs</h1>
                <div className="cjd-job-sections">
                    <section className="cjd-ongoing-jobs">
                        <h2>Ongoing Jobs</h2>
                        {jobs.filter(job => job.status === 'IN_PROGRESS' || job.status === 'PENDING').map(renderJobCard)}
                    </section>

                    <section className="cjd-completed-jobs">
                        <h2>Completed Jobs</h2>
                        {jobs.filter(job => job.status === 'COMPLETED').map(renderJobCard)}
                    </section>

                    <section className="cjd-previous-jobs">
                        <h2>Previous Jobs</h2>
                        {jobs.filter(job => job.status === 'CANCELLED').map(renderJobCard)}
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ClientJobDashboard;