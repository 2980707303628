import React from 'react';
import './ServiceFeatures.css';
import image1 from './../../images/ServiceFeature2.png';
import image2 from './../../images/ServiceFeature3.png';
import image3 from './../../images/ServiceFeature4.png';
import image4 from './../../images/ServiceFeature5.png';
import image5 from './../../images/ServiceFeature6.png';
import image6 from './../../images/ServiceFeature7.png';
import image7 from './../../images/ServiceFeature8.png';
import image8 from './../../images/ServiceFeature1.png';

const ServiceFeatures = () => {
  const features = [
    { image: image1, label: 'Start Free Trial' },
    { image: image2, label: 'Your Own Retoucher' },
    { image: image3, label: '100% Quality Assured' },
    { image: image4, label: 'On Time Delivery' },
    { image: image5, label: 'Rush Service' },
    { image: image6, label: 'Bulk Order Processing' },
    { image: image7, label: '100% Secured File Transfer' },
    { image: image8, label: 'Easy Payment System' },
  ];

  return (
    <section className="service-features">
      <h2>OUR SERVICE FEATURES</h2>
      <p>
        We have been providing professional image manipulation, photo retouching, and photo editing
        services for the last 12 years. Here are the key features of our team to provide you the highest quality
        of work.
      </p>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-item">
            <img src={feature.image} alt={feature.label} className="feature-icon" />
            <span className="feature-label">{feature.label}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServiceFeatures;