import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ServiceList.css'
const ServiceList = () => {
  const [services, setServices] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('http://15.206.148.121:8080/api/services'); // Adjust this URL to match your API endpoint
        setServices(response.data.services);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch services. Please try again later.');
        setLoading(false);
      }
    };

    fetchServices();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="service-list">
      <h1>Our Services</h1>
      {services.map((service) => (
        <div key={service.id} className="service-item">
          <h2>{service.name}</h2>
          <p>{service.description}</p>
          <img src = {`http://15.206.148.121:8080/${service.beforeImage}`} width={100} height={100}/>
          <img src = {`http://15.206.148.121:8080/${service.afterImage}`} width={100} height={100} />
          {service.subServices && service.subServices.length > 0 && (
            <div className="sub-services">
              <h3>Sub-services:</h3>
              <ul>
                {service.subServices.map((subService) => (
                  <li key={subService.id}>{subService.name}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ServiceList;