import React from 'react';
import './JewelryServices.css';

const JewelryServices = ({services,id}) => {
    
//   const services = [
//         // 'Background Retouching',
//         // 'Jewelry Photo Enhancement',
//         // 'High-End Retouching',
//         // 'Color Correction',
//         // 'Jewelry Shadow Creation',
//         // 'Improvement Of Color'
//   ];

  return (
    <div className="js-container">
      <div className="js-services-grid">
        {services.map((service, index) => (
          <div key={index} className="js-service-item">
            <span className="js-service-dot"></span>
            {service.name}
          </div>
        ))}
      </div>
      <div className="js-buttons-container">
        <button className="js-button js-quote-button">Get A Quote &gt;</button>
        <button className="js-button js-trial-button">Get Free Trial &gt;</button>
        <a href={`/specific-services/${id}`}className="js-view-all">View All &gt;</a>
      </div>
    </div>
  );
};

export default JewelryServices;