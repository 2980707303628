import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Navbar from '../Header/Navbar'
import Footer from '../Footer/Footer'
import './PaymentPage.css'

const PaymentPage = () => {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState(null);
    const [clientName, setClientName] = useState('');
    const [clientEmail, setClientEmail] = useState('');
    const [clientPhone, setClientPhone] = useState('');
    const [clientGstin, setClientGstin] = useState('');
    const [clientAddress, setClientAddress] = useState('');
    const [clientCity, setClientCity] = useState('');
    const [clientState, setClientState] = useState('');
    const [clientCountry, setClientCountry] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [payment, setPayment] = useState(null);
    const [showInvoice, setShowInvoice] = useState(false);

    useEffect(() => {
        const fetchJobDetails = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`http://15.206.148.121:8080/api/jobs/${jobId}`, {
                    headers: { 'Authorization': `${token}` }
                });
                console.log('Job details response:', response.data);
                setJob(response.data.job);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching job details:', err);
                setError('Failed to fetch job details: ' + err.message);
                setLoading(false);
            }
        };

        fetchJobDetails();
    }, [jobId]);

    useEffect(() => {
        console.log('Updated job data:', job);
    }, [job]);

    useEffect(() => {
        if (job && job.totalPrice) {
            const numericPrice = parseFloat(job.totalPrice);
            if (!isNaN(numericPrice)) {
                setJob(prevJob => ({...prevJob, totalPrice: numericPrice}));
            } else {
                console.error('Invalid total price from backend:', job.totalPrice);
                setError('Invalid price received from server');
            }
        }
    }, [job]);

    const handlePaymentSuccess = async (paymentDetails) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'http://15.206.148.121:8080/api/payments',
                {
                    jobId: job.id,
                    seriesType: paymentMethod === 'PAYPAL' ? 'PP' : 'KR',
                    amount: job.totalPrice,
                    currency: job.currency,
                    paymentMethod: paymentMethod,
                    clientName,
                    clientEmail,
                    clientPhone,
                    clientGstin,
                    clientAddress,
                    clientCity,
                    clientState,
                    clientCountry,
                    ...paymentDetails
                },
                { headers: { 'Authorization': `${token}` } }
            );
            setPayment(response.data.payment);
            setShowInvoice(true);
        } catch (err) {
            console.error('Error processing payment:', err);
            setError('Payment processing failed: ' + err.message);
        }
    };

    const handlePayPalPayment = (data, actions) => {
        return actions.order.capture().then((details) => {
            handlePaymentSuccess({
                paypalOrderId: data.orderID,
                paypalPaymentId: details.id,
            });
        });
    };

    const handleRazorpayPayment = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'http://15.206.148.121:8080/api/payments/create-order',
                {
                    amount: job.totalPrice , // Razorpay expects amount in paise
                    currency: job.currency,
                    jobId: job.id
                },
                { headers: { 'Authorization': `${token}` } }
            );

            const options = {
                key: 'rzp_test_ygTvsqmsNgMBQG', // Replace with your Razorpay Key ID
                amount: response.data.amount,
                currency: response.data.currency,
                name: 'Your Company Name',
                description: `Payment for Job #${job.id}`,
                order_id: response.data.id,
                handler: function (response) {
                    handlePaymentSuccess({
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    });
                },
                prefill: {
                    name: clientName,
                    email: clientEmail,
                    contact: clientPhone
                },
                theme: {
                    color: '#3399cc'
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        } catch (err) {
            console.error('Error initiating Razorpay payment:', err);
            setError('Failed to initiate Razorpay payment: ' + err.message);
        }
    };
    const generateInvoiceHTML = () => {
        if (!job || !payment) return '';
    
        return `
        <!DOCTYPE html>
        <html lang="en">
    
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Export Invoice</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 20px;
                    font-size: 14px;
                    color: #333;
                }
                .w-full { width: 100%; }
                .w-1/2 { width: 50%; }
                .border-collapse { border-collapse: collapse; }
                .border-spacing-0 { border-spacing: 0; }
                .align-top { vertical-align: top; }
                .text-right { text-align: right; }
                .text-center { text-align: center; }
                .whitespace-nowrap { white-space: nowrap; }
                .font-bold { font-weight: bold; }
                .text-sm { font-size: 0.875rem; }
                .text-xs { font-size: 0.75rem; }
                .bg-slate-100 { background-color: #f1f5f9; }
                .text-main { color: #4472C4; }
                .bg-main { background-color: #4472C4; }
                .text-white { color: white; }
                .text-slate-400 { color: #94a3b8; }
                .text-neutral-600 { color: #525252; }
                .text-neutral-700 { color: #404040; }
                .border-b { border-bottom: 1px solid #e2e8f0; }
                .border-b-2 { border-bottom: 2px solid #4472C4; }
                .py-3 { padding-top: 0.75rem; padding-bottom: 0.75rem; }
                .py-4 { padding-top: 1rem; padding-bottom: 1rem; }
                .py-6 { padding-top: 1.5rem; padding-bottom: 1.5rem; }
                .py-10 { padding-top: 2.5rem; padding-bottom: 2.5rem; }
                .px-14 { padding-left: 3.5rem; padding-right: 3.5rem; }
                .pl-2 { padding-left: 0.5rem; }
                .pl-3 { padding-left: 0.75rem; }
                .pl-4 { padding-left: 1rem; }
                .pr-3 { padding-right: 0.75rem; }
                .pr-4 { padding-right: 1rem; }
                .p-3 { padding: 0.75rem; }
                .h-12 { height: 3rem; }
                footer {
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    background-color: #f1f5f9;
                    color: #525252;
                    text-align: center;
                    font-size: 0.75rem;
                    padding: 0.75rem 0;
                }
                .logo { 
                    width: 100px; 
                    height: auto; 
                    margin-bottom: 0.5rem;
                }
                .address-block, .bill-to-block {
                    line-height: 1.5;
                    margin-top: 1rem;
                }
                .bill-to-block p {
                    margin: 0;
                }
            </style>
        </head>
    
        <body>
            <div class="py-4">
                <div class="px-14 py-6">
                    <table class="w-full border-collapse border-spacing-0">
                        <tbody>
                            <tr>
                                <td class="w-1/2 align-top">
                                    <img src="../../images/logo.png" alt="Company Logo" class="logo">
                                    <div class="font-bold">COMPANY NAME</div>
                                </td>
                                <td class="w-1/2 align-top text-right">
                                    <div class="text-sm">
                                        <table class="border-collapse border-spacing-0 ml-auto">
                                            <tbody>
                                                <tr>
                                                    <td class="pr-4">
                                                        <div>
                                                            <p class="whitespace-nowrap text-slate-400 text-right">Date</p>
                                                            <p class="whitespace-nowrap font-bold text-main text-right">${new Date().toLocaleDateString('en-GB')}</p>
                                                        </div>
                                                    </td>
                                                    <td class="pl-4">
                                                        <div>
                                                            <p class="whitespace-nowrap text-slate-400 text-right">Invoice #</p>
                                                            <p class="whitespace-nowrap font-bold text-main text-right">${payment.seriesNumber}</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td class="w-1/2 align-top pt-6">
                                    <div class="address-block">
                                        <strong>KANCHI RASTOGI</strong><br>
                                        742 HBU Extension<br>
                                        Ajmer, Rajasthan - 305004<br>
                                        India<br>
                                        7742555547<br>
                                        rastogi.kanchi12@gmail.com<br>
                                        GSTIN: 08AVKPR1990F1Z6<br>
                                        PAN: AVKPR1990F
                                    </div>
                                </td>
                                <td class="w-1/2 align-top pt-6">
                                    <div class="text-sm text-neutral-600 bill-to-block">
                                        <p class="font-bold">BILL TO:</p>
                                        <p>${clientName}</p>
                                        <p>${clientAddress}</p>
                                        <p>${clientCity}, ${clientState}</p>
                                        <p>${clientCountry}</p>
                                        <p>${clientPhone}</p>
                                        <p>${clientEmail}</p>
                                        ${clientGstin ? `<p>GSTIN: ${clientGstin}</p>` : ''}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                <div class="px-14 py-10 text-sm text-neutral-700">
                    <table class="w-full border-collapse border-spacing-0">
                        <thead>
                            <tr>
                                <td class="border-b-2 border-main pb-3 pl-3 font-bold text-main">Date</td>
                                <td class="border-b-2 border-main pb-3 pl-2 font-bold text-main">Description</td>
                                <td class="border-b-2 border-main pb-3 pl-2 text-right font-bold text-main">Image Count</td>
                                <td class="border-b-2 border-main pb-3 pl-2 text-right font-bold text-main">Rate</td>
                                <td class="border-b-2 border-main pb-3 pl-2 pr-3 text-right font-bold text-main">Amount</td>
                            </tr>
                        </thead>
                        <tbody>
                            ${job.subServices.map((subService, index) => `
                                <tr>
                                    <td class="border-b py-3 pl-3">${new Date(job.createdAt).toLocaleDateString('en-GB')}</td>
                                    <td class="border-b py-3 pl-2">${subService.name}</td>
                                    <td class="border-b py-3 pl-2 text-right">${job.numberOfPhotos}</td>
                                    <td class="border-b py-3 pl-2 text-right">${(job.totalPrice / job.subServices.length).toFixed(2)} ${job.currency}</td>
                                    <td class="border-b py-3 pl-2 pr-3 text-right">${(job.totalPrice / job.subServices.length).toFixed(2)} ${job.currency}</td>
                                </tr>
                            `).join('')}
                            <tr>
                                <td colspan="5">
                                    <table class="w-full border-collapse border-spacing-0">
                                        <tbody>
                                            <tr>
                                                <td class="w-full"></td>
                                                <td>
                                                    <table class="w-full border-collapse border-spacing-0">
                                                        <tbody>
                                                            <tr>
                                                                <td class="border-b p-3">
                                                                    <div class="whitespace-nowrap text-slate-400">Sub-Total:</div>
                                                                </td>
                                                                <td class="border-b p-3 text-right">
                                                                    <div class="whitespace-nowrap font-bold text-main">${job.totalPrice} ${job.currency}</div>
                                                                </td>
                                                            </tr>
                                                            ${payment.igstAmount > 0 
                                                                ? `
                                                                    <tr>
                                                                        <td class="p-3">
                                                                            <div class="whitespace-nowrap text-slate-400">IGST at 18%:</div>
                                                                        </td>
                                                                        <td class="p-3 text-right">
                                                                            <div class="whitespace-nowrap font-bold text-main">${payment.igstAmount} ${job.currency}</div>
                                                                        </td>
                                                                    </tr>
                                                                `
                                                                : `
                                                                    <tr>
                                                                        <td class="p-3">
                                                                            <div class="whitespace-nowrap text-slate-400">CGST at 9%:</div>
                                                                        </td>
                                                                        <td class="p-3 text-right">
                                                                            <div class="whitespace-nowrap font-bold text-main">${payment.cgstAmount} ${job.currency}</div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td class="p-3">
                                                                            <div class="whitespace-nowrap text-slate-400">SGST at 9%:</div>
                                                                        </td>
                                                                        <td class="p-3 text-right">
                                                                            <div class="whitespace-nowrap font-bold text-main">${payment.sgstAmount} ${job.currency}</div>
                                                                        </td>
                                                                    </tr>
                                                                `
                                                            }
                                                            <tr>
                                                                <td class="bg-main p-3">
                                                                    <div class="whitespace-nowrap font-bold text-white">Grand Total:</div>
                                                                </td>
                                                                <td class="bg-main p-3 text-right">
                                                                    <div class="whitespace-nowrap font-bold text-white">${payment.totalAmount} ${job.currency}</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
                </div>
    
                <div class="px-14 py-10 text-sm text-neutral-700">
                    <p class="text-main font-bold">Notes</p>
                    <p class="italic">SUPPLY MEANT FOR EXPORT UNDER LUT WITHOUT PAYMENT OF IGST</p>
                    <p class="italic">THANK YOU FOR YOUR BUSINESS!!</p>
                    <p class="italic">Seal & Sign</p>
                </div>
    
                <footer>
                    KANCHI RASTOGI
                    <span class="text-slate-300 px-2">|</span>
                    rastogi.kanchi12@gmail.com
                    <span class="text-slate-300 px-2">|</span>
                    7742555547
                </footer>
            </div>
        </body>
    
        </html>
        `;
    };
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!job) return <div>No job data available</div>;

    return (
        <>
            <Navbar/>
            <div className="payment-page">
                <h2>Payment for Job #{job.id}</h2>
                <div className="job-details">
                    <p>Total Amount: {job.totalPrice} {job.currency}</p>
                    {/* Display other relevant job details */}
                </div>
                <form>
                    <div>
                        <label htmlFor="clientName">Full Name:</label>
                        <input
                            type="text"
                            id="clientName"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="clientEmail">Email:</label>
                        <input
                            type="email"
                            id="clientEmail"
                            value={clientEmail}
                            onChange={(e) => setClientEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="clientPhone">Phone Number:</label>
                        <input
                            type="tel"
                            id="clientPhone"
                            value={clientPhone}
                            onChange={(e) => setClientPhone(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="clientGstin">GSTIN (if applicable):</label>
                        <input
                            type="text"
                            id="clientGstin"
                            value={clientGstin}
                            onChange={(e) => setClientGstin(e.target.value)}
                        />
                    </div>
                    <div>
                        <label htmlFor="clientAddress">Address:</label>
                        <textarea
                            id="clientAddress"
                            value={clientAddress}
                            onChange={(e) => setClientAddress(e.target.value)}
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label htmlFor="clientCity">City:</label>
                        <input
                            type="text"
                            id="clientCity"
                            value={clientCity}
                            onChange={(e) => setClientCity(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="clientState">State:</label>
                        <input
                            type="text"
                            id="clientState"
                            value={clientState}
                            onChange={(e) => setClientState(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="clientCountry">Country:</label>
                        <input
                            type="text"
                            id="clientCountry"
                            value={clientCountry}
                            onChange={(e) => setClientCountry(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="paymentMethod">Payment Method:</label>
                        <select
                            id="paymentMethod"
                            value={paymentMethod}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                            required
                        >
                            <option value="">Select Payment Method</option>
                            <option value="PAYPAL">PayPal</option>
                            <option value="OTHER">Razorpay</option>
                        </select>
                    </div>
                </form>

                {paymentMethod === 'PAYPAL' && job && job.totalPrice && job.currency && (
                    <PayPalScriptProvider options={{ "client-id": "ARwM4ZIweCSIsIAUZgOcPW8YX2e4_z7renE14fsc-lS2af6X_zhoi3b5D6cn44T6hk-DzF5si86KE07T", currency: job.currency }}>
                        <PayPalButtons
                            createOrder={(data, actions) => {
                                return actions.order.create({
                                    purchase_units: [{
                                        amount: {
                                            value: job.totalPrice.toFixed(2),
                                            currency_code: job.currency
                                        },
                                    }],
                                });
                            }}
                            onApprove={handlePayPalPayment}
                            onError={(err) => {
                                console.error('PayPal Error:', err);
                                setError('PayPal Error: ' + err.message);
                            }}
                        />
                    </PayPalScriptProvider>
                )}

    {paymentMethod === 'OTHER' && (
                    <button onClick={handleRazorpayPayment}>Pay with Razorpay</button>
                )}

                {showInvoice && (
                    <div className="invoice-modal">
                        <div className="invoice-modal-content">
                            <h2>Invoice</h2>
                            <iframe
                                srcDoc={generateInvoiceHTML()}
                                style={{ width: '100%', height: '500px', border: 'none' }}
                            ></iframe>
                            <button onClick={() => setShowInvoice(false)}>Close</button>
                            <button onClick={() => {
                                const invoiceWindow = window.open('', '_blank');
                                invoiceWindow.document.write(generateInvoiceHTML());
                                invoiceWindow.document.close();
                                invoiceWindow.print();
                            }}>Print Invoice</button>
                        </div>
                    </div>
                )}

                <style>{`
                    .invoice-modal {
                        position: fixed;
                        z-index: 1;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        overflow: auto;
                        background-color: rgba(0,0,0,0.4);
                    }

                    .invoice-modal-content {
                        background-color: #fefefe;
                        margin: 15% auto;
                        padding: 20px;
                        border: 1px solid #888;
                        width: 80%;
                    }
                `}</style>
            </div>
            <Footer/>
        </>
    );
};

export default PaymentPage;