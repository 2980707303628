// Landing.js
import React from 'react'
import Navbar from '../../components/Header/Navbar'
import Hero from '../../components/Hero/Hero'
import AboutUs from '../../components/AboutUs/AboutUs'
import OurServices from '../../components/OurServices/OurServices'
import ServiceFeatures from '../../components/ServiceFeatures/ServiceFeatures'
import ImageGallery from '../../components/ImageGallery/ImageGallery'
import WorkingProcess from '../../components/WorkingProcess/WorkingProcess'
import MeetHappyClient from '../../components/MeetHappyClient/MeetHappyClient'
// import ServicesBrands from '../../components/ServicesBrands/ServicesBrands'
// import PaymentSection from '../../components/PaymentSection/PaymentSection'
import FreeTrialBanner from '../../components/FreeTrialBanner/FreeTrialBanner'
import Footer from '../../components/Footer/Footer'
import OrderProcess from '../../components/OrderProcess/OrderProcess'
import ExperienceBanner from '../../components/ExperienceBanner/ExperienceBanner'
import EnquiryNow from '../../components/EnquiryNow/EnquiryNow'
import Ticker from '../../components/Ticker/Ticker'
import ContactUs from '../../components/EnquiryNow/ContactUs'

const Landing = () => {
  return (
    <div>
        <Navbar/>
      
        <div id="home"><Hero/></div>
        <div id="about"><AboutUs/></div>
        <Ticker/>
        <div id="services"><OurServices showAll={false}/></div>
        <div id="features"><ServiceFeatures/></div>
        <div id="gallery"><ImageGallery/></div>
        {/* <div id="process"><WorkingProcess/></div> */}
        <div id="clients"><MeetHappyClient/></div>
        <div id="trial"><FreeTrialBanner/></div>
        <OrderProcess/>
        <ContactUs/>
        {/* <EnquiryNow/> */}
        {/* <ExperienceBanner/> */}
       
        <Footer/>
    </div>
  )
}

export default Landing