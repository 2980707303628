// App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing/Landing';
import Login from './pages/Login/Login';
import ServiceQuote from './components/OurServices/ServiceQuote';
import FullServicesPage from './pages/FullServicesPage/FullServicesPage';
import Signup from './pages/Signup/Signup'
import AddServiceForm from './components/admin/SuperAdmin/AddServiceForm';
import { AuthProvider } from './utils/AuthContext';
import PaymentPage from './components/PaymentSection/PaymentPage';
import { CurrencyProvider } from './CurrencyContext';
import AddSubService from './components/admin/SuperAdmin/AddSubServices';
import AdminDashboard from './components/admin/SuperAdmin/AdminDashboard';
import Editor from './components/admin/Editor/Editor';
import Manager from './components/admin/Manager/Manager';
import ServiceList from './components/admin/ServiceList';
import ScrollToTop from './utils/ScrollToTop';
import AdminLogin from './components/admin/adminLogin';
import AboutUs from './components/AboutUs/AboutUs';
import OurServices from './components/OurServices/OurServices';
import ServiceFeatures from './components/ServiceFeatures/ServiceFeatures';
import ImageGallery from './components/ImageGallery/ImageGallery';
import MeetHappyClient from './components/MeetHappyClient/MeetHappyClient';
import WorkingProcess from './components/WorkingProcess/WorkingProcess';
import FreeTrialBanner from './components/FreeTrialBanner/FreeTrialBanner';
import ImageUploadForm from './components/admin/ImageUploadForm';
import FullPortfolioPage from './components/ImageGallery/FullPortfolioPage';
import ClientJobDashboard from './components/client/ClientJobDashboard';
import AssignJob from './components/admin/SuperAdmin/AssignJob';
import UserAdminTable from './components/admin/SuperAdmin/UserAdminTable';
import ServicesTable from './components/admin/SuperAdmin/ServicesTable';
import SpecificServicesPage from './pages/SpecificServicesPage/SpecificServicesPage';
import TawkToChat from '../src/components/TawkToChat/TawkToChat';


const App = () => {
  return (
    <AuthProvider>
      <CurrencyProvider>
        <Router>
          <ScrollToTop />

          <div className='container'>
            <Routes>
              <Route path='/' element={<Landing />} />
              <Route path='/full-services' element={<FullServicesPage />} />
              <Route path='/specific-services/:id' element={<SpecificServicesPage/>} />
              <Route path="/service-quote/:serviceId" element={<ServiceQuote />} />
              <Route path="/payment/:jobId" element={<PaymentPage />} />
              <Route path='/login' element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              {/* admin links */}
              <Route path='/admin' element={<AdminDashboard />} />
              <Route path='/add-service' element={<AddServiceForm />} />
              <Route path='/add-subservice' element={<AddSubService />} />
              <Route path='/upload-image' element={<ImageUploadForm />} />
              <Route path = '/service-list'  element={<ServiceList/>}/>
              <Route path='/admin-login' element={<AdminLogin />} />
              <Route path='/editor' element={<Editor />} />
              <Route path='/manager' element={<Manager />} />
              <Route path="/assign-job" element={<AssignJob/>}/>
              <Route path="user-table" element={<UserAdminTable/>}/>
              <Route path="/service-table" element={<ServicesTable/>}/>
              {/* Add new routes for navbar elements */}
              <Route path='/about' element={<AboutUs />} />
              <Route path='/services' element={<OurServices showAll={true} />} />
              <Route path='/features' element={<ServiceFeatures />} />
              <Route path='/gallery' element={<ImageGallery />} />
              <Route path='/process' element={<WorkingProcess />} />
              <Route path='/clients' element={<MeetHappyClient />} />
              <Route path='/trial' element={<FreeTrialBanner />} />
              <Route path='/dashboard' element={<ClientJobDashboard />} />
              <Route path="/full-portfolio" element={<FullPortfolioPage />} />
            </Routes>
          </div>
          <TawkToChat /> {/* Add this line */}
        </Router>
      </CurrencyProvider>
    </AuthProvider>
    // <UserAdminTable/>
  );
}
export default App;