import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faListAlt, faImages } from '@fortawesome/free-solid-svg-icons';
import './OrderProcess.css';

const OrderProcess = () => {
  return (
    <div className="op-container">
      <h1 className="op-title">How to Place Your Order at Product Editing</h1>
      
      <p className="op-description">
        We try to make our relationships with clients absolutely transparent and comprehensive, so you won't experience problems ordering from We Edit Photos.
      </p>
      
      <div className="op-steps">
        <div className="op-step">
          <div className="op-icon">
            <FontAwesomeIcon icon={faCloudUploadAlt} />
          </div>
          <h3>Send Your Photos</h3>
          <p>Use the form on our website to attach and send images. You can submit as many photos as you need in any image format.</p>
        </div>
        
        <div className="op-step">
          <div className="op-icon">
            <FontAwesomeIcon icon={faListAlt} />
          </div>
          <h3>Provide your instructions</h3>
          <p>Indicate what image editing changes you want us to make. Write details, highlight areas in photos, and send us a sample.</p>
        </div>
        
        <div className="op-step">
          <div className="op-icon">
            <FontAwesomeIcon icon={faImages} />
          </div>
          <h3>Get Your Edited Photos</h3>
          <p>We guarantee fast delivery without any loss of quality. The standard turnaround time is 48 hours. We send images to the email you indicated during the registration.</p>
        </div>
      </div>
      
      <div className="op-buttons">
        <button className="op-button op-button-primary">Order Now</button>
        <button className="op-button op-button-secondary">Get Free Quotes</button>
      </div>
    </div>
  );
};

export default OrderProcess;