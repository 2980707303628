// Services-Services.js
import React from 'react';
import './Services-Services.css';

const ServicesServices = () => {
  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap" rel="stylesheet" />
      <div className="ss_services-container">
        <h2 className="ss_services-title">Our Services</h2>
        <div className="ss_services-underline"></div>
        <p className="ss_services-description">
          Cras dictum in magna vitae volutpat. Phasellus vel volutpat dui, eu placerat lacus. Donec eget rutrum massa, vel semper urna
          Suspendisse auctor ante nibh, ac pretium est vehicula at. Sed ullamcorper erat vitae egestas lobortis. Pellentesque habitant morbi tristique
          Donec augue purus, tempus id pellentesque eget, maximus et massa.Nunc turpis metus, vestibulum non tincidunt non, pretium a neque.
          Integer feugiat tortor condimentum commodo convallis. Morbi posuere odio et pharetra auctor. Nulla dictum commodo venenatis.
          Phasellus sed turpis eget libero euismod lobortis vel nec mauris.
        </p>
      </div>
    </>
  );
};

export default ServicesServices;