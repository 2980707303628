import React, { useState, useRef, useEffect } from 'react';
import './SpecificServices.css';
import Image1Before from './../../images/jewelry1-before.jpeg';
import Image1After from './../../images/jewelry1-after.jpeg';
import Image2Before from './../../images/jewelry1-before.jpeg';
import Image2After from './../../images/jewelry1-after.jpeg';

const imageSets = [
  {
    id: 1,
    before: Image1Before,
    after: Image1After,
    thumbnail: Image1After,
  },
  {
    id: 2,
    before: Image2Before,
    after: Image2After,
    thumbnail: Image2After,
  },
  // Add more image sets as needed
];

const SpecificServices = ({title,description,afterImage,beforeImage}) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
  };

  const handleTouchMove = (e) => {
    if (isDragging && containerRef.current) {
      const touch = e.touches[0];
      const rect = containerRef.current.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const handleMouseUpGlobal = () => {
      setIsDragging(false);
    };
    document.addEventListener('mouseup', handleMouseUpGlobal);
    document.addEventListener('mouseleave', handleMouseUpGlobal);
    return () => {
      document.removeEventListener('mouseup', handleMouseUpGlobal);
      document.removeEventListener('mouseleave', handleMouseUpGlobal);
    };
  }, []);

  const handleThumbnailClick = (index) => {
    setCurrentSetIndex(index);
  };

  const handleNavigation = (direction) => {
    if (direction === 'prev') {
      setCurrentSetIndex((prevIndex) => 
        prevIndex > 0 ? prevIndex - 1 : imageSets.length - 1
      );
    } else if (direction === 'next') {
      setCurrentSetIndex((prevIndex) => 
        prevIndex < imageSets.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  const currentSet = imageSets[currentSetIndex];

  return (
    <div className="ss5x2a">
      <h2 className="ss5x2b">{title}</h2>
      <div className="ss5x2c">
        <div className="ss5x2d">
          <p className="ss5x2e">
            {description}
          </p>
          <ul className="ss5x2f">
            <li>Background clean-up & removal</li>
            <li>Color correction & enhancement</li>
            <li>Digital image masking</li>
            <li>Dust & scratch cleaning</li>
            <li>Metal polishing, adding natural sparks</li>
            <li>Diamond & stones recoloring</li>
            <li>Fixing reflection, shadow, and shine</li>
            <li>Contrast and brightness adjustments</li>
            <li>Adding hallmark stamps</li>
          </ul>
          <a href="#pricing" className="ss5x2g">View Pricing</a>
        </div>
        <div className="ss5x2h">
          <div 
            className="ss5x2i" 
            ref={containerRef}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <img src={beforeImage} alt="Before" className="ss5x2j ss5x2k" />
            <img 
              src={afterImage} 
              alt="After" 
              className="ss5x2j ss5x2l" 
              style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
            />
            <div 
              className="ss5x2m" 
              style={{ left: `${sliderPosition}%` }}
            >
              <div className="ss5x2n">
                <div className="ss5x2o ss5x2p"></div>
                <div className="ss5x2o ss5x2q"></div>
              </div>
            </div>
            <span className={`ss5x2r ss5x2s ${sliderPosition <= 10 ? 'ss5x2t' : ''}`}>Before</span>
            <span className={`ss5x2r ss5x2u ${sliderPosition >= 90 ? 'ss5x2t' : ''}`}>After</span>
          </div>
        </div>
      </div>
      <div className="ss5x2v">
        <button className="ss5x2w">Get A Quote</button>
        <button className="ss5x2w">Get Free Trial</button>
      </div>
    </div>
  );
};

export default SpecificServices;