// LoginForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import './LoginForm.css';

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");
    try {
      const response = await axios.post("http://15.206.148.121:8080/api/users/signin", formData);
      if (response.data) {
        console.log(response.data);
        login(response.data.user);
        localStorage.setItem('token', response.data.token);
        navigate('/');
      }
    } catch (error) {
      console.log(error);
      setError("Invalid email or password. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleSignUp = (e) => {
    e.preventDefault();
    navigate('/signup');
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Welcome Back</h2>
        <p className="login-subtitle">Please sign in to your account</p>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              id="email"
              placeholder='Enter your email'
              name='email' 
              type='email' 
              value={formData.email}
              onChange={handleChange} 
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input 
              id="password"
              placeholder='Enter your password' 
              name='password' 
              type='password' 
              value={formData.password}
              onChange={handleChange} 
              required
            />
          </div>
          {error && <div className="error-message">{error}</div>}
          <button type="submit" className="login-button" disabled={isLoading}>
            {isLoading ? 'Signing In...' : 'Sign In'}
          </button>
        </form>
        <div className="login-footer">
          <a href="#" className="forgot-password">Forgot Password?</a>
          <p className="signup-prompt">Don't have an account? <a href="#" onClick={handleSignUp}>Sign Up</a></p>
        </div>
      </div>
    </div>
  );
}

export default Login;