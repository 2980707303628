import React, { useState, useEffect, useRef } from 'react';
import './Hero.css';
import Picture1 from '../../images/Picture1.png'
import banner1 from '../../assets/Home Page_Banner1.jpg';
import banner2 from '../../assets/Home Page_Banner2.jpg';
import banner3 from '../../assets/Home Page_Banner3.jpg';
import banner4 from '../../assets/Home Page_Banner4.jpg';
import banner5 from '../../assets/Home Page_Banner5.jpg';
import banner6 from '../../assets/Home Page_Banner6.jpg';
import backgroundVideo from '../../assets/hero_video.mp4';

const heroData = [
  {
    title: "Hair Masking",
    description: "Hair masking is a photo editing technique used to isolate and refine hair details in images. It allows for precise control over hair strands, making it easier to change backgrounds or apply other effects without losing the natural look of the hair.",
    image: Picture1,
    showButton: true
  },
  {
    title: "",
    description: "",
    image: banner1,
    showButton: false
  },
  {
    title: "",
    description: "",
    image: banner2,
    showButton: false
  },
  {
    title: "",
    description: "",
    image: banner3,
    showButton: false
  },
  {
    title: "",
    description: "",
    image: banner4,
    showButton: false
  },
  {
    title: "",
    description: "",
    image: banner5,
    showButton: false
  },
  {
    title: "",
    description: "",
    image: banner6,
    showButton: false
  },
];

const Hero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const slideTimer = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % heroData.length);
    }, 3000); // Change slide every 5 seconds

    return () => clearInterval(slideTimer);
  }, []);

  useEffect(() => {
    const switchToVideo = () => {
      setShowVideo(true);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().catch(error => console.error("Video playback failed:", error));
      }
    };

    const switchToContent = () => {
      setShowVideo(false);
    };

    if (!showVideo && imagesLoaded === heroData.length) {
      const timer = setTimeout(switchToVideo, 3000 * heroData.length); // Wait for all images to be shown
      return () => clearTimeout(timer);
    } else if (showVideo) {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.addEventListener('ended', switchToContent);
        return () => videoElement.removeEventListener('ended', switchToContent);
      }
    }
  }, [showVideo, imagesLoaded]);

  const handleImageLoad = () => {
    setImagesLoaded(prev => prev + 1);
  };

  return (
    <section className="hero">
      <div className={`hero-content-container ${showVideo ? 'slide-left' : ''}`}>
        {heroData.map((slide, index) => (
          <div
            key={index}
            className={`hero-slide ${index === currentSlide ? 'active' : ''}`}
          >
            <img 
              src={slide.image} 
              alt={slide.title} 
              onLoad={handleImageLoad} 
              style={{width: '100%', height: '100%', objectFit: 'cover'}}
            />
            <div className="hero-content">
              {slide.title && <h1>{slide.title}</h1>}
              {slide.description && <p>{slide.description}</p>}
              {slide.showButton && <button className="btn-try">Try Us Free</button>}
            </div>
          </div>
        ))}
      </div>
      <div className={`hero-video-container ${showVideo ? 'slide-left' : ''}`}>
        <video 
          ref={videoRef}
          className="hero-video" 
          muted 
          playsInline
        >
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="hero-indicators-custom">
        {heroData.map((_, index) => (
          <button
            key={index}
            className={`indicator-custom ${index === currentSlide ? 'active-custom' : ''}`}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </section>
  );
};

export default Hero;