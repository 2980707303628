import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AssignJob.css';

const AssignJob = () => {
  const [jobs, setJobs] = useState([]);
  const [editors, setEditors] = useState([]);
  const [selectedJob, setSelectedJob] = useState('');
  const [selectedEditor, setSelectedEditor] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    fetchJobs();
    fetchEditors();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get('http://15.206.148.121:8080/api/jobs');
      setJobs(response.data.jobs.filter(job => job.status === 'PENDING'));
    } catch (err) {
      setError('Error fetching jobs');
    }
  };

  const fetchEditors = async () => {
    try {
      const response = await axios.get(' http://15.206.148.121:8080/api/admin?role=EDITOR');
      setEditors(response.data.editors);
    } catch (err) {
      setError('Error fetching editors');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!selectedJob || !selectedEditor) {
      setError('Please select both a job and an editor');
      return;
    }
    
    try {
      const response = await axios.put('http://15.206.148.121:8080/api/jobs/assign-job', {
        jobId: selectedJob,
        editorId: selectedEditor
      });
      setMessage(response.data.message);
      setSelectedJob('');
      setSelectedEditor('');
      fetchJobs(); // Refresh the job list
    } catch (err) {
      setError(err.response?.data?.error || 'Error assigning job');
    }
  };

  return (
    <div className="assign-job-container">
      <h2 className='job-title'>Assign Job to Editor</h2>
      {message && <div className="success-message">{message}</div>}
      {error && <div className="error-message">{error}</div>}
      <form onSubmit={handleSubmit} className="assign-job-form">
        <div className="form-group">
          <label htmlFor="job-select">Select Job:</label>
          <select
            id="job-select"
            value={selectedJob}
            onChange={(e) => setSelectedJob(e.target.value)}
          >
            <option value="">-- Select a job --</option>
            {jobs.map((job) => (
              <option key={job.id} value={job.id}>
                Job #{job.id} - {job.subServices.map(s => s.name).join(', ')}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="editor-select">Select Editor:</label>
          <select
            id="editor-select"
            value={selectedEditor}
            onChange={(e) => setSelectedEditor(e.target.value)}
          >
            <option value="">-- Select an editor --</option>
            {editors.map((editor) => (
              <option key={editor.id} value={editor.id}>
                {editor.username}
              </option>
            ))}
          </select>
        </div>
        <button type="submit" className="assign-button">Assign Job</button>
      </form>
    </div>
  );
};

export default AssignJob;