// FullPortfolioPage.js

import React from 'react';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import Navbar from '../../components/Header/Navbar';
import Footer from '../../components/Footer/Footer';

const FullPortfolioPage = () => {
  return (
    <div>
      <Navbar />
      <ImageGallery showAll={true} />
      <Footer />
    </div>
  );
};

export default FullPortfolioPage;