import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Editor = () => {

  const [assignedJobs, setAssignedJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAssignedJobs();
  }, []);

  const fetchAssignedJobs = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/jobs/assigned');
      setAssignedJobs(response.data.assignedJobs);
      setLoading(false);
    } catch (err) {
      setError('Error fetching assigned jobs');
      setLoading(false);
    }
  };

  const renderJobStatus = (status) => {
    const statusColors = {
      PENDING: '#ffa500',
      IN_PROGRESS: '#1e90ff',
      COMPLETED: '#32cd32',
      CANCELLED: '#ff0000'
    };

    return (
      <span className="job-status" style={{ backgroundColor: statusColors[status] }}>
        {status}
      </span>
    );
  };

  if (loading) {
    return <div className="loading">Loading assigned jobs...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="assigned-jobs-container">
      <h2>Assigned Jobs</h2>
      {assignedJobs.length === 0 ? (
        <p className="no-jobs">No jobs assigned to you at the moment.</p>
      ) : (
        <ul className="job-list">
          {assignedJobs.map((job) => (
            <li key={job.id} className="job-item">
              <h3>Job #{job.id}</h3>
              <p><strong>Title:</strong> {job.title}</p>
              <p><strong>Description:</strong> {job.description}</p>
              <p><strong>Status:</strong> {renderJobStatus(job.status)}</p>
              <p><strong>Progress:</strong> {job.progress}%</p>
              <p><strong>Deadline:</strong> {new Date(job.deadline).toLocaleDateString()}</p>
              {job.clientDropboxLink && (
                <p>
                  <strong>Client Dropbox Link:</strong>{' '}
                  <a href={job.clientDropboxLink} target="_blank" rel="noopener noreferrer">
                    View Files
                  </a>
                </p>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Editor