import axios from "axios";

const BASE_URL = "http://15.206.148.121:8080/api";

export const getAllImages = async () => {
    try {
        const response = await axios.get(`${BASE_URL}/images`);
        if (response.data) {
            return response.data.images;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const getImage = async (id) => {
    try {
        const response = await axios.get(`${BASE_URL}/images/${id}`);
        if (response.data) {
            return response.data.image;
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const createImage = async (imageFile) => {
    try {
        const formData = new FormData();
        formData.append('image', imageFile);

        const response = await axios.post(`${BASE_URL}/images`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const updateImage = async (id, imageFile) => {
    try {
        const formData = new FormData();
        formData.append('image', imageFile);

        const response = await axios.put(`${BASE_URL}/images/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const deleteImage = async (id) => {
    try {
        const response = await axios.delete(`${BASE_URL}/images/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}