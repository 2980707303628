// FullServicesPage.js

import React, { useEffect, useState } from 'react';
import OurServices from '../../components/OurServices/OurServices';
import Navbar from '../../components/Header/Navbar';
import Footer from '../../components/Footer/Footer';
import ServicesBanner from '../../components/ServicesBanner/ServicesBanner';
import ServicesServices from '../../components/Services-Services/Services-Services';
import JewelryEditingSlider from '../../components/JewelryEditingSlider/JewelryEditingSlider';
import JewelryServices from '../../components/JewelryServices/JewelryServices';
import axios from 'axios';

const FullServicesPage = () => {

  const [services,setServices] = useState([]);
  const [loading,setLoading] = useState(true);

  useEffect(()=>{
       const fetchServices = async()=>{
       try {
        const response = await axios.get("http://15.206.148.121:8080/api/services");
        if(response.data){
          // console.log(response.data);
          setServices(response.data.services);
          setLoading(false);
        }
       } catch (error) {
        console.log(error);
       }
       }
       fetchServices();
  },[])
  
  if(loading) return <div>Loading...</div>
    return (
    
      <div>
        <Navbar/>
        <ServicesBanner/>
        <ServicesServices/>
        
        {/* <JewelryEditingSlider name={"Product Editing"}/>
         */}
         {
          services.map((service,key)=>{
            return <>
            <JewelryEditingSlider name={service.name} subServices={service.subServices}/>
            <JewelryServices services={service.subServices} id={service.id}/>
            </>
          })
         }
       
        
        {/* <OurServices showAll={true}/> */}
        <Footer/>
      </div>
    );
  };
  
  export default FullServicesPage;