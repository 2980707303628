import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { createImage } from '../../api/imageApi';

const FormContainer = styled.form`
  max-width: 500px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const fadeInScale = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  animation: ${fadeInScale} 0.5s ease-out;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
`;

const FileInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 2px dashed #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  cursor: pointer;

  &:hover {
    border-color: #3498db;
  }

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const pulseAnimation = keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
  }
`;

const Button = styled.button`
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background-color: #2980b9;
    animation: ${pulseAnimation} 1.5s infinite;
  }

  &:active {
    transform: scale(0.98);
  }
`;

const Message = styled.p`
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;

  ${({ isError }) => isError
    ? `background-color: #ffebee; color: #c62828;`
    : `background-color: #e8f5e9; color: #2e7d32;`
  }
`;

const ImagePreview = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-radius: 4px;
`;

const ImageUploadForm = () => {
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsError(false);

    if (!image) {
      setMessage('Please select an image to upload.');
      setIsError(true);
      return;
    }

    try {
      const result = await createImage(image);
      setMessage('Image uploaded successfully!');
      setImage(null);
      setPreview(null);
      // Reset the file input
      e.target.reset();
      console.log('Upload result:', result);
    } catch (error) {
      setMessage('Error uploading image. Please try again.');
      setIsError(true);
      console.error('Upload error:', error);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Title>Upload Image</Title>
      
      <FormGroup>
        <Label htmlFor="image">Select Image:</Label>
        <FileInput
          type="file"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
          required
        />
      </FormGroup>

      {preview && <ImagePreview src={preview} alt="Preview" />}

      <Button type="submit">Upload Image</Button>

      {message && (
        <Message isError={isError}>
          {message}
        </Message>
      )}
    </FormContainer>
  );
};

export default ImageUploadForm;