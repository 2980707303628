// ServicesBanner.js
import React, { useState } from 'react';
import './ServicesBanner.css';
import defaultBannerImage1 from './../../images/company1.jpg';
import defaultBannerImage2 from './../../images/company2.jpg';
import defaultBannerImage3 from './../../images/happyclient.jpg';

const ServicesBanner = ({title, images, description}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const defaultImages = [defaultBannerImage1, defaultBannerImage2, defaultBannerImage3];
  const bannerImages = images || defaultImages;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % bannerImages.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + bannerImages.length) % bannerImages.length);
  };

  return (
    <div className="sb_services-banner-container">
      <div className="sb_services-image-wrapper">
        <img 
          src={bannerImages[currentIndex]}
          alt={`Services Banner ${currentIndex + 1}`} 
          className="sb_services-banner-image" 
        />
        <button className="sb_nav-btn sb_prev" onClick={prevSlide}>&lt;</button>
        <button className="sb_nav-btn sb_next" onClick={nextSlide}>&gt;</button>
        <div className="sb_pagination">
          {bannerImages.map((_, index) => (
            <span 
              key={index} 
              className={`sb_dot ${index === currentIndex ? 'sb_active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>
      <div className="sb_services-content-wrapper">
        <h1 className="sb_services-title">{title || "HIGH END PHOTO RETOUCHING SERVICE"}</h1>
        <p className="sb_services-description">
          {description || "ONLY $12 PER IMAGE RETOUCHING"}
        </p>
        <div className="sb_button-group-container">
          <button className="sb_btn sb_btn-quote">Get A Quote &gt;</button>
          <button className="sb_btn sb_btn-trial">Get Free Trial &gt;</button>
        </div>
      </div>
    </div>
  );
};

export default ServicesBanner;