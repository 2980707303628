// Login.js

import React from 'react';
import LoginHeader from '../../components/LoginForm/LoginHeader';
import LoginForm from '../../components/LoginForm/LoginForm';
import Footer from '../../components/Footer/Footer';

export default function Login() {
  return (
    <div className="login-page">
      <LoginHeader />
      <LoginForm />
      <Footer/>
    </div>
  );
}
