import React, { useState, useRef, useEffect } from 'react';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './ContactUs.css'

const ContactUs = () => {
    const [focusedInputs, setFocusedInputs] = useState({});
    const [captchaText, setCaptchaText] = useState('');
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        captcha: ''
    });
    const [agreeTerms, setAgreeTerms] = useState(false);
    const inputRefs = useRef([]);
  
    useEffect(() => {
        inputRefs.current = inputRefs.current.slice(0, 5);
        generateCaptcha();
    }, []);
  
    const handleFocus = (index) => {
        setFocusedInputs(prev => ({ ...prev, [index]: true }));
    };
  
    const handleBlur = (index) => {
        if (inputRefs.current[index].value === '') {
            setFocusedInputs(prev => ({ ...prev, [index]: false }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAgreeChange = (e) => {
        setAgreeTerms(e.target.checked);
    };

    const generateCaptcha = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 5; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptchaText(result);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!agreeTerms) {
            alert('Please agree to the terms before submitting.');
            return;
        }
        if (formData.captcha === captchaText) {
            // Handle form submission logic here
            console.log(formData);
            alert('Form submitted successfully!');
        } else {
            alert('Captcha incorrect. Please try again.');
            generateCaptcha();
        }
    };

    return (
        <section className="cu-container">
            <span className="cu-big-circle"></span>
            <img src="img/shape.png" className="cu-square" alt="" />
            <div className="cu-form">
                <div className="cu-contact-info">
                    <h3 className="cu-title">Let's get in touch</h3>
                    <p className="cu-text">
                        We're here to help and answer any question you might have. We look forward to hearing from you!
                    </p>

                    <div className="cu-info">
                        <div className="cu-information" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} />
                            <p>742, H B U Extn. Pushkar Road, Ajmer, 305004</p>
                        </div>
                        <div className="cu-information" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <p>rastogi.kanchi12@gmail.com</p>
                        </div>
                        <div className="cu-information" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <FontAwesomeIcon icon={faPhone} />
                            <p>+91 7742555547</p>
                        </div>
                    </div>

                    <div className="cu-social-media">
                        <p>Connect with us:</p>
                        <div className="cu-social-icons">
                            <a href="#" aria-label="Facebook">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                            <a href="#" aria-label="Twitter">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="#" aria-label="Instagram">
                                <FontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="#" aria-label="LinkedIn">
                                <FontAwesomeIcon icon={faLinkedinIn} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="cu-contact-form">
                    <span className="cu-circle cu-circle-one"></span>
                    <span className="cu-circle cu-circle-two"></span>

                    <form onSubmit={handleSubmit} autoComplete="off">
                        <h3 className="cu-title">Contact us</h3>
                        {['name', 'email', 'phone', 'message'].map((field, index) => (
                            <div key={field} className={`cu-input-container ${focusedInputs[index] ? 'cu-focus' : ''} ${field === 'message' ? 'cu-textarea' : ''}`}>
                                {field === 'message' ? (
                                    <textarea
                                        name={field}
                                        className="cu-input"
                                        ref={el => inputRefs.current[index] = el}
                                        onFocus={() => handleFocus(index)}
                                        onBlur={() => handleBlur(index)}
                                        onChange={handleChange}
                                        value={formData[field]}
                                        required
                                    ></textarea>
                                ) : (
                                    <input
                                        type={field === 'email' ? 'email' : field === 'phone' ? 'tel' : 'text'}
                                        name={field}
                                        className="cu-input"
                                        ref={el => inputRefs.current[index] = el}
                                        onFocus={() => handleFocus(index)}
                                        onBlur={() => handleBlur(index)}
                                        onChange={handleChange}
                                        value={formData[field]}
                                        required
                                    />
                                )}
                                <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                                <span>{field.charAt(0).toUpperCase() + field.slice(1)}</span>
                            </div>
                        ))}
                        <div className="cu-captcha-row">
                            <div className={`cu-input-container ${focusedInputs[4] ? 'cu-focus' : ''}`}>
                                <input
                                    type="text"
                                    name="captcha"
                                    className="cu-input cu-captcha-input"
                                    ref={el => inputRefs.current[4] = el}
                                    onFocus={() => handleFocus(4)}
                                    onBlur={() => handleBlur(4)}
                                    onChange={handleChange}
                                    value={formData.captcha}
                                    required
                                />
                                <label htmlFor="captcha">Captcha</label>
                                <span>Captcha</span>
                            </div>
                            <div className="cu-captcha-image" onClick={generateCaptcha}>
                                {captchaText}
                            </div>
                        </div>
                        <div className="cu-agree-terms">
                            <input
                                type="checkbox"
                                id="agreeTerms"
                                checked={agreeTerms}
                                onChange={handleAgreeChange}
                                required
                            />
                            <label htmlFor="agreeTerms">
                            By submitting the form you give us your consent to process the personal data specified by you in the fields above according to our Privacy Policy
                            </label>
                        </div>
                        <input type="submit" value="Send" className="cu-btn" />
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactUs;