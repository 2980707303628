import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { createService, getService } from '../../../api/serviceAPI';

const FormContainer = styled.form`
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(90deg, #3498db, #2980b9, #3498db);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${gradientAnimation} 5s ease infinite, ${fadeIn} 1s ease-out;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    width: 50px;
    height: 3px;
    background-color: #3498db;
    transform: translateX(-50%);
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 100px;
  }
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Textarea = styled(Input).attrs({ as: 'textarea' })`
  min-height: 100px;
  resize: vertical;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  padding: 0.75rem 1rem;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const Message = styled.p`
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;

  ${({ isError }) => isError
    ? `background-color: #ffebee; color: #c62828;`
    : `background-color: #e8f5e9; color: #2e7d32;`
  }
`;

const AddServiceForm = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [beforeImage, setBeforeImage] = useState(null);
  const [afterImage, setAfterImage] = useState(null);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    getService(7);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsError(false);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('beforeImage', beforeImage);
    formData.append('afterImage', afterImage);

    try {
      await createService(formData);
      setMessage('Service added successfully!');
      setName('');
      setDescription('');
      setBeforeImage(null);
      setAfterImage(null);
    } catch (error) {
      setMessage('Error adding service. Please try again.');
      setIsError(true);
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <Title>Add New Service</Title>
      
      <FormGroup>
        <Label htmlFor="name">Service Name</Label>
        <Input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="description">Description</Label>
        <Textarea
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="beforeImage">Before Image</Label>
        <FileInput
          type="file"
          id="beforeImage"
          onChange={(e) => setBeforeImage(e.target.files[0])}
          required
        />
        <FileInputLabel htmlFor="beforeImage">
          {beforeImage ? beforeImage.name : 'Choose Before Image'}
        </FileInputLabel>
      </FormGroup>

      <FormGroup>
        <Label htmlFor="afterImage">After Image</Label>
        <FileInput
          type="file"
          id="afterImage"
          onChange={(e) => setAfterImage(e.target.files[0])}
          required
        />
        <FileInputLabel htmlFor="afterImage">
          {afterImage ? afterImage.name : 'Choose After Image'}
        </FileInputLabel>
      </FormGroup>

      <Button type="submit">Add Service</Button>

      {message && (
        <Message isError={isError}>
          {message}
        </Message>
      )}
    </FormContainer>
  );
};

export default AddServiceForm;