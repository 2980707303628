import React from 'react';
import './FreeTrialBanner.css';
import leftImage from './../../images/company1.jpg';
import rightImage from './../../images/company2.jpg';

const FreeTrialBanner = () => {
  return (
    <div className="free-trial-banner">
      <img src={leftImage} alt="Left banner image" className="banner-image left" />
      <div className="banner-content">
        <h2 className="banner-title">
          Try <span className="highlight-domain">productediting.com</span> services <span className="highlight-free">FREE!!</span>
        </h2>
        <p className="banner-description">
          Photo editing sample images only!
        </p>
        <p className="banner-description second">
          Please submit high-quality, original photographs for best results.
        </p>
        <button className="get-quote-button">Get a Free Quote</button>
      </div>
      <img src={rightImage} alt="Right banner image" className="banner-image right" />
    </div>
  );
};

export default FreeTrialBanner;