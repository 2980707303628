// ImageGallery.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ImageGallery.css';
import { useNavigate } from 'react-router-dom';
import banner1 from './../../images/homepage_banner1.jpg';
import banner2 from './../../images/homepage_banner2.jpg';
import banner3 from './../../images/homepage_banner3.jpg';
import banner4 from './../../images/Home Page_Group Services Portfolio2.jpg';
import banner5 from './../../images/Home Page_Group Services Portfolio4.jpg';
import banner6 from './../../images/Home Page_Group Services Portfolio5.jpg';
import banner7 from './../../images/Home Page_Group Services Portfolio6.jpg';


const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const PrevIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

const NextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const ImageGallery = ({ showAll = false }) => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const bannerImages = [banner1, banner2, banner3, banner4, banner5, banner6, banner7];
  const bannerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  

  useEffect(() => {
    fetchImages();
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextBanner();
    }, 5000);
    return () => clearInterval(timer);
  }, [currentBannerIndex]);

  const fetchImages = async () => {
    try {
      const response = await axios.get('http://15.206.148.121:8080/api/slider-images');
      setImages(response.data.images);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching images:', err);
      setError('Failed to load images. Please try again later.');
      setLoading(false);
    }
  };

  const handleViewFullPortfolio = () => {
    navigate('/full-portfolio');
  };

  const GalleryItem = ({ imagePath, id, index }) => (
    <div className="ig-gallery-item" onClick={() => openLightbox(index)}>
      <img src={`http://15.206.148.121:8080/${imagePath}`} alt={`Image ${id}`} />
      <div className="ig-overlay">
        <span className="ig-search-icon">
          <SearchIcon />
        </span>
      </div>
    </div>
  );

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setIsLightboxOpen(true);
  };
  
  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };
  
  const prevImage = () => {
    setLightboxIndex((prev) => (prev - 1 + images.length) % images.length);
  };
  
  const nextImage = () => {
    setLightboxIndex((prev) => (prev + 1) % images.length);
  };

  const handleDotClick = (index) => {
    setCurrentBannerIndex(index);
    scrollToBanner(index);
  };

  const handleBannerScroll = () => {
    const scrollPosition = bannerRef.current.scrollLeft;
    const imageWidth = bannerRef.current.clientWidth;
    const newIndex = Math.round(scrollPosition / imageWidth);
    setCurrentBannerIndex(newIndex);
  };

  const scrollToBanner = (index) => {
    if (bannerRef.current) {
      const imageWidth = bannerRef.current.clientWidth;
      bannerRef.current.scrollTo({
        left: index * imageWidth,
        behavior: 'smooth'
      });
    }
  };

  const handlePrevBanner = () => {
    const newIndex = (currentBannerIndex - 1 + bannerImages.length) % bannerImages.length;
    setCurrentBannerIndex(newIndex);
    scrollToBanner(newIndex);
  };

  const handleNextBanner = () => {
    const newIndex = (currentBannerIndex + 1) % bannerImages.length;
    setCurrentBannerIndex(newIndex);
    scrollToBanner(newIndex);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - bannerRef.current.offsetLeft);
    setScrollLeft(bannerRef.current.scrollLeft);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    handleBannerScroll();
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - bannerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    bannerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX - bannerRef.current.offsetLeft);
    setScrollLeft(bannerRef.current.scrollLeft);
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const x = e.touches[0].pageX - bannerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    bannerRef.current.scrollLeft = scrollLeft - walk;
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    if (touchStart - touchEnd > 75) {
      handleNextBanner();
    } else if (touchStart - touchEnd < -75) {
      handlePrevBanner();
    } else {
      scrollToBanner(currentBannerIndex);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="ig-container">
      <div className={`ig-gallery ${showAll ? 'ig-full-portfolio' : ''}`}>
        {images.map((image, index) => (
          <GalleryItem
            key={image.id}
            imagePath={image.imagePath}
            id={image.id}
            index={index}
          />
        ))}
      </div>
      <div className="ig-banner-container">
        <div
          className="ig-banner"
          ref={bannerRef}
          onScroll={handleBannerScroll}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {bannerImages.map((banner, index) => (
            <img
              key={index}
              src={banner}
              alt={`Banner ${index + 1}`}
              className={`ig-banner-image ${index === currentBannerIndex ? 'ig-active' : ''}`}
              draggable="false"
            />
          ))}
        </div>
        <button className="ig-banner-nav ig-prev" onClick={handlePrevBanner}>
          &lt;
        </button>
        <button className="ig-banner-nav ig-next" onClick={handleNextBanner}>
          &gt;
        </button>
        <div className="ig-banner-dots">
          {bannerImages.map((_, index) => (
            <span
              key={index}
              className={`ig-banner-dot ${index === currentBannerIndex ? 'ig-active' : ''}`}
              onClick={() => handleDotClick(index)}
            ></span>
          ))}
        </div>
      </div>
      {!showAll && (
        <button className="ig-full-portfolio-btn" onClick={handleViewFullPortfolio}>Full Portfolio</button>
      )}
      {isLightboxOpen && (
        <div className="ig-lightbox-overlay" onClick={closeLightbox}>
          <div className="ig-lightbox-content" onClick={(e) => e.stopPropagation()}>
            <img 
              src={`http://15.206.148.121:8080/${images[lightboxIndex].imagePath}`} 
              alt={`Image ${images[lightboxIndex].id}`} 
            />
            <button className="ig-lightbox-close" onClick={closeLightbox}>&times;</button>
            <button className="ig-lightbox-prev" onClick={prevImage}><PrevIcon /></button>
            <button className="ig-lightbox-next" onClick={nextImage}><NextIcon /></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;