import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faRocket, faTag, faTicketAlt } from '@fortawesome/free-solid-svg-icons';
import './ticker.css';

const Ticker = () => {
  const [counts, setCounts] = useState({
    quality: 0,
    delivery: 0,
    price: 0,
    discount: 0
  });
  const [isVisible, setIsVisible] = useState(false);
  const tickerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (tickerRef.current) {
      observer.observe(tickerRef.current);
    }

    return () => {
      if (tickerRef.current) {
        observer.unobserve(tickerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let interval;
    if (isVisible) {
      interval = setInterval(() => {
        setCounts(prevCounts => ({
          quality: Math.min(prevCounts.quality + 1, 100),
          delivery: Math.min(prevCounts.delivery + 50, 5000),
          price: Math.min(prevCounts.price + 1, 2),
          discount: Math.min(prevCounts.discount + 1, 30)
        }));
      }, 50);
    }

    return () => clearInterval(interval);
  }, [isVisible]);

  return (
    <div className="ticker-container" ref={tickerRef}>
      <div className="ticker-item">
        <FontAwesomeIcon icon={faThumbsUp} className="ticker-icon" />
        <h3 className="ticker-title">Quality Services</h3>
        <p className="ticker-value">{counts.quality}%</p>
        <p className="ticker-subtitle">high quality</p>
      </div>
      <div className="ticker-item">
        <FontAwesomeIcon icon={faRocket} className="ticker-icon" />
        <h3 className="ticker-title">We Can Deliver</h3>
        <p className="ticker-value">{counts.delivery}</p>
        <p className="ticker-subtitle">images/day</p>
      </div>
      <div className="ticker-item">
        <FontAwesomeIcon icon={faTag} className="ticker-icon" />
        <h3 className="ticker-title">Starts From</h3>
        <p className="ticker-value">{counts.price}$</p>
        <p className="ticker-subtitle">per Image</p>
      </div>
      <div className="ticker-item">
        <FontAwesomeIcon icon={faTicketAlt} className="ticker-icon" />
        <h3 className="ticker-title">Discount Upto</h3>
        <p className="ticker-value">{counts.discount}%</p>
        <p className="ticker-subtitle">on bulk order</p>
      </div>
    </div>
  );
};

export default Ticker;