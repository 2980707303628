// AboutUs.js
import React from 'react';
import './AboutUs.css';
import picture from '../../images/illustration.jpg';

const AboutUs = () => {
  return (
    <section className='about-us'>
      <div className="background-circles">
        <div className="circle circle-1"></div>
        <div className="circle circle-2"></div>
        <div className="circle circle-3"></div>
      </div>
      <h2 className="section-title">Who We Are</h2>
      <div className="about-content-wrapper">
        <div className="about-content">
          <h3>About Our Company</h3>
          <p>
            Our Product Editing services are helpful in all kinds of scenarios. Maybe
            you don't have the time, the team or the bandwidth to edit your ecommerce
            photos internally, and if you have lots of products, it's not uncommon to have
            thousands of product photos that need to be edited. Selling on different
            marketplaces, each with their own requirements, makes the job even bigger. 
          </p>
        </div>
        <div className='about-image'>
          <div className="illustration">
            <img src={picture} alt="Illustration of our product editing process" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutUs