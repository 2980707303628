import React from 'react';
import './WorkingProcess.css';
import WorkingProcess1 from './../../images/WorkingProcess1.png';
import WorkingProcess2 from './../../images/WorkingProcess2.png';
import WorkingProcess3 from './../../images/WorkingProcess3.png';

const WorkingProcess = () => {
  const steps = [
    { image: WorkingProcess1, title: 'Quick Uploading' },
    { image: WorkingProcess2, title: 'Professional Editing' },
    { image: WorkingProcess3, title: 'Timely Delivery' },
  ];

  return (
    <div className="wp-container">
      <h2 className="wp-title">OUR IMAGE EDITING WORKING PROCESS</h2>
      <div className="wp-steps">
        {steps.map((step, index) => (
          <div key={index} className="wp-step">
            <div className="wp-step-icon">
              <img src={step.image} alt={step.title} className="wp-icon-image" />
            </div>
            <p className="wp-step-title">{step.title}</p>
          </div>
        ))}
      </div>
      <h3 className="wp-tagline">High-end retouching service from industry veterans</h3>
      <p className="wp-description">
        This visual representation will help you to get a better understanding of our bulk photo editing service process.
      </p>
    </div>
  );
};

export default WorkingProcess;