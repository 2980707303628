// LoginHeader.js
import React from 'react';
import Navbar from '../Header/Navbar'

const LoginHeader = () => {
  return (
    <>
      <Navbar/>
    </>
  );
};

export default LoginHeader;