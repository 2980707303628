//ServicesTable.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faList } from '@fortawesome/free-solid-svg-icons';
import './ServicesTable.css';

const AddServiceModal = ({ onClose, onSubmit }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [beforeImage, setBeforeImage] = useState(null);
  const [afterImage, setAfterImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('beforeImage', beforeImage);
    formData.append('afterImage', afterImage);
    onSubmit(formData);
  };

  return (
    <div className="st-modal">
      <div className="st-modal-content">
        <h2>Add New Service</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
          <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
          <input type="file" onChange={(e) => setBeforeImage(e.target.files[0])} accept="image/*" required />
          <input type="file" onChange={(e) => setAfterImage(e.target.files[0])} accept="image/*" required />
          <button type="submit" className="st-submit-btn">Add Service</button>
          <button type="button" onClick={onClose} className="st-cancel-btn">Cancel</button>
        </form>
      </div>
    </div>
  );
};

const EditServiceModal = ({ service, onClose, onSubmit }) => {
  const [name, setName] = useState(service.name);
  const [description, setDescription] = useState(service.description);
  const [beforeImage, setBeforeImage] = useState(null);
  const [afterImage, setAfterImage] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    if (beforeImage) formData.append('beforeImage', beforeImage);
    if (afterImage) formData.append('afterImage', afterImage);
    onSubmit(formData);
  };

  return (
    <div className="st-modal">
      <div className="st-modal-content">
        <h2>Edit Service</h2>
        <form onSubmit={handleSubmit}>
          <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} required />
          <textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} required />
          <input type="file" onChange={(e) => setBeforeImage(e.target.files[0])} accept="image/*" />
          <input type="file" onChange={(e) => setAfterImage(e.target.files[0])} accept="image/*" />
          <button type="submit" className="st-submit-btn">Update Service</button>
          <button type="button" onClick={onClose} className="st-cancel-btn">Cancel</button>
        </form>
      </div>
    </div>
  );
};

const SubservicesModal = ({ service, onClose }) => {
  return (
    <div className="st-modal">
      <div className="st-modal-content">
        <h2>Subservices for {service.name}</h2>
        {/* Add subservices content here */}
        <button onClick={onClose} className="st-close-btn">Close</button>
      </div>
    </div>
  );
};

const ServicesTable = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSubservicesModal, setShowSubservicesModal] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      setLoading(true);
      const response = await axios.get('http://15.206.148.121:8080/api/services');
      setServices(response.data.services);
      setLoading(false);
    } catch (err) {
      setError('Error fetching services');
      setLoading(false);
    }
  };

  const handleAddService = async (serviceData) => {
    try {
      await axios.post('http://15.206.148.121:8080/api/services', serviceData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowAddModal(false);
      fetchServices();
    } catch (err) {
      setError('Error adding service');
    }
  };

  const handleEditService = async (id, serviceData) => {
    try {
      await axios.put(`http://15.206.148.121:8080/api/services/${id}`, serviceData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setShowEditModal(false);
      fetchServices();
    } catch (err) {
      setError('Error updating service');
    }
  };

  const handleDeleteService = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      try {
        await axios.delete(`http://15.206.148.121:8080/api/services/${id}`);
        fetchServices();
      } catch (err) {
        setError('Error deleting service');
      }
    }
  };

  const toggleDescription = (id) => {
    setExpandedDescriptions(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const renderDescription = (service) => {
    const isExpanded = expandedDescriptions[service.id];
    const words = service.description.split(' ');
    const shortDescription = words.slice(0, 20).join(' ');
    const isLongDescription = words.length > 20;

    if (!isLongDescription) {
      return <p>{service.description}</p>;
    }

    return (
      <>
        <p>
          {isExpanded ? service.description : `${shortDescription}...`}
          {isLongDescription && (
            <button 
              className="st-read-more-btn" 
              onClick={() => toggleDescription(service.id)}
            >
              {isExpanded ? 'Read less' : 'Read more'}
            </button>
          )}
        </p>
      </>
    );
  };

  if (loading) {
    return <div className="st-loading">Loading services...</div>;
  }

  if (error) {
    return <div className="st-error">{error}</div>;
  }

  return (
    <div className="st-container">
      <h2>Services</h2>
      <button className="st-add-btn" onClick={() => setShowAddModal(true)}>Add New Service</button>
      <table className="st-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Description</th>
            <th>Before Image</th>
            <th>After Image</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {services.map(service => (
            <tr key={service.id}>
              <td>{service.id}</td>
              <td>{service.name}</td>
              <td className="st-description-cell">
                {renderDescription(service)}
              </td>
              <td>
                <img src={`http://15.206.148.121:8080/${service.beforeImage}`} alt="Before" className="st-image" />
              </td>
              <td>
                <img src={`http://15.206.148.121:8080/${service.afterImage}`} alt="After" className="st-image" />
              </td>
              <td>{new Date(service.createdAt).toLocaleString()}</td>
              <td>{new Date(service.updatedAt).toLocaleString()}</td>
              <td className="st-action-buttons">
                <button className="st-icon-btn st-edit-btn" onClick={() => {
                  setSelectedService(service);
                  setShowEditModal(true);
                }}>
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button className="st-icon-btn st-delete-btn" onClick={() => handleDeleteService(service.id)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
                <button className="st-icon-btn st-subservices-btn" onClick={() => {
                  setSelectedService(service);
                  setShowSubservicesModal(true);
                }}>
                  <FontAwesomeIcon icon={faList} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {showAddModal && (
        <AddServiceModal
          onClose={() => setShowAddModal(false)}
          onSubmit={handleAddService}
        />
      )}

      {showEditModal && (
        <EditServiceModal
          service={selectedService}
          onClose={() => setShowEditModal(false)}
          onSubmit={(serviceData) => handleEditService(selectedService.id, serviceData)}
        />
      )}

      {showSubservicesModal && (
        <SubservicesModal
          service={selectedService}
          onClose={() => setShowSubservicesModal(false)}
        />
      )}
    </div>
  );
};

export default ServicesTable;