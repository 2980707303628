import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled, { keyframes } from 'styled-components';
import { getAllServices } from '../../../api/serviceAPI';

const FormContainer = styled.div`
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Title = styled.h2`
  font-size: 2rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
  animation: ${fadeIn} 0.8s ease-out;
`;

const SubTitle = styled.h3`
  font-size: 1.5rem;
  color: #555;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: #555;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const Textarea = styled(Input).attrs({ as: 'textarea' })`
  min-height: 100px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const PriceGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  padding: 0.75rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2980b9;
  }
`;

const RemoveButton = styled(Button)`
  background-color: #e74c3c;

  &:hover {
    background-color: #c0392b;
  }
`;

const AddButton = styled(Button)`
  background-color: #2ecc71;

  &:hover {
    background-color: #27ae60;
  }
`;

const Message = styled.p`
  margin-top: 1rem;
  padding: 0.75rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 600;

  ${({ isError }) => isError
    ? `background-color: #ffebee; color: #c62828;`
    : `background-color: #e8f5e9; color: #2e7d32;`
  }
`;

const AddSubService = () => {
  const [services, setServices] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [serviceId, setServiceId] = useState('');
  const [prices, setPrices] = useState([{ price: '', currency: '' }]);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const response = await axios.get('http://15.206.148.121:8080/api/services');
      setServices(response.data.services);
    } catch (error) {
      console.error('Error fetching services:', error);
      setMessage('Error fetching services. Please try again.');
      setIsError(true);
    }
  };

  const handlePriceChange = (index, field, value) => {
    const newPrices = [...prices];
    newPrices[index][field] = value;
    setPrices(newPrices);
  };

  const addPriceField = () => {
    setPrices([...prices, { price: '', currency: '' }]);
  };

  const removePriceField = (index) => {
    const newPrices = prices.filter((_, i) => i !== index);
    setPrices(newPrices);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setIsError(false);

    try {
      const response = await axios.post('http://15.206.148.121:8080/api/subServices/withPrices', {
        name,
        description,
        serviceId: Number(serviceId),
        prices
      });

      setMessage('SubService and prices created successfully');
      console.log('Created SubService:', response.data);
      console.log('Created Prices:', response.data);

      // Reset form
      setName('');
      setDescription('');
      setServiceId('');
      setPrices([{ price: '', currency: '' }]);
    } catch (error) {
      setIsError(true);
      setMessage('Error creating SubService and prices: ' + (error.response?.data?.message || error.message));
    }
  };

  return (
    <FormContainer>
      <Title>Add New SubService with Prices</Title>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">SubService Name</Label>
          <Input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="description">Description</Label>
          <Textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label htmlFor="serviceId">Service</Label>
          <Select
            id="serviceId"
            value={serviceId}
            onChange={(e) => setServiceId(e.target.value)}
            required
          >
            <option value="">Select a service</option>
            {services.map((service) => (
              <option key={service.id} value={service.id}>
                {service.name}
              </option>
            ))}
          </Select>
        </FormGroup>

        <SubTitle>Prices</SubTitle>
        {prices.map((price, index) => (
          <PriceGroup key={index}>
            <Input
              type="number"
              value={price.price}
              onChange={(e) => handlePriceChange(index, 'price', e.target.value)}
              placeholder="Price"
              required
            />
            <Input
              type="text"
              value={price.currency}
              onChange={(e) => handlePriceChange(index, 'currency', e.target.value)}
              placeholder="Currency"
              required
            />
            <RemoveButton type="button" onClick={() => removePriceField(index)}>Remove</RemoveButton>
          </PriceGroup>
        ))}
        <AddButton type="button" onClick={addPriceField}>Add Price</AddButton>

        <Button type="submit">Add SubService with Prices</Button>
      </form>

      {message && (
        <Message isError={isError}>
          {message}
        </Message>
      )}
    </FormContainer>
  );
};

export default AddSubService;