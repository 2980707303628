import axios from "axios";

export const getAllServices = async() =>{
    try {
        const response = await axios.get("http://15.206.148.121:8080/api/services");
        if(response.data){
            // console.log(response.data);
            return response.data.services
        }
    } catch (error) {
        console.log(error)
    }
}

export const getService = async(id)=>{
    try {
        const response = await axios.get(`http://15.206.148.121:8080/api/services/${id}`);
        if(response.data){
            console.log(response.data);
            return response.data;
        }
    } catch (error) {
        console.log(error);
    }
}

export const createService = async(formData)=>{
    try {
        const response = await axios.post('http://15.206.148.121:8080/api/services', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
         console.log(response.data)
         return response.data;
      } catch (error) {
        console.log(error);
      }
}