//scrollUtils.js

export const scrollToSection = (elementId) => {
    const element = document.getElementById(elementId)
    const navbar = document.querySelector('.navbar');

    if (element && navbar) {
        const navbarHeight = navbar.offsetHeight;
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - navbarHeight;

        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        })
    }
}