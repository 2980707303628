import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UserAdminTable.css';

const UserAdminTable = () => {
  const [activeClients, setActiveClients] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('clients');
  const [showModal, setShowModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [jobsData, setJobsData] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [clientsResponse, adminsResponse] = await Promise.all([
        axios.get('http://15.206.148.121:8080/api/users'),
        axios.get('http://15.206.148.121:8080/api/admin')
      ]);
      setActiveClients(clientsResponse.data.users);
      setAdmins(adminsResponse.data.admins);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleViewJob = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`http://15.206.148.121:8080/api/jobs/user-jobs?userId=${id}`);
      setJobsData(response.data.jobs);
      setSelectedItemId(id);
      setShowModal(true);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching user jobs:', error);
      setShowModal(true);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`http://15.206.148.121:8080/api/${activeTab === 'clients' ? 'users' : 'admin'}/${id}`);
      fetchData();
    } catch (error) {
      console.error('Error deleting item:', error);
      setError('Error deleting item: ' + error.message);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await axios.delete(`http://15.206.148.121:8080/api/jobs/${jobId}`);
      // Refresh the jobs data for the current user
      const response = await axios.get(`http://15.206.148.121:8080/api/jobs/user-jobs?userId=${selectedItemId}`);
      setJobsData(response.data.jobs);
    } catch (error) {
      console.error('Error deleting job:', error);
      setError('Error deleting job: ' + error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItemId(null);
    setJobsData(null);
  };

  if (loading) {
    return <div className="uat-loading">Loading data...</div>;
  }
  if (error) {
    return <div className="uat-error">{error}</div>;
  }

  const renderActionButtons = (id) => (
    <div className="uat-action-buttons">
      <button className="uat-icon-button uat-view-button" onClick={() => handleViewJob(id)} title="View Job">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"/>
          <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
        </svg>
      </button>
      <button className="uat-icon-button uat-delete-button" onClick={() => handleDelete(id)} title="Delete">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
          <path fill="none" d="M0 0h24v24H0z"/>
          <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm1 2H6v12h12V8zm-9 3h2v6H9v-6zm4 0h2v6h-2v-6zM9 4v2h6V4H9z"/>
        </svg>
      </button>
    </div>
  );

  const renderTable = (data, columns) => (
    <div className='uat-data-table-wrapper'>
      <table className="uat-data-table">
        <thead>
          <tr>
            {columns.map(col => <th key={col.key}>{col.label}</th>)}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={item.id}>
              {columns.map(col => <td key={col.key}>{col.render ? col.render(item[col.key]) : item[col.key]}</td>)}
              <td>{renderActionButtons(item.id)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const clientColumns = [
    { key: 'id', label: 'ID' },
    { key: 'username', label: 'Username' },
    { key: 'email', label: 'Email' },
    { key: 'isVerified', label: 'Verified', render: (value) => value ? 'Yes' : 'No' },
    { key: 'createdAt', label: 'Created At', render: (value) => new Date(value).toLocaleString() },
  ];

  const adminColumns = [
    { key: 'id', label: 'ID' },
    { key: 'username', label: 'Username' },
    { key: 'email', label: 'Email' },
    { key: 'role', label: 'Role' },
    { key: 'status', label: 'Status' },
    { key: 'createdAt', label: 'Created At', render: (value) => new Date(value).toLocaleString() },
  ];

  const renderJobsModal = () => (
    <div className="uat-modal">
      <div className="uat-modal-content">
        <h2>User Jobs</h2>
        {jobsData && jobsData.length > 0 ? (
          <div className="uat-jobs-list">
            {jobsData.map(job => (
              <div key={job.id} className="uat-job-item">
                <h3>Job ID: {job.id}</h3>
                <p>Status: {job.status}</p>
                <p>Created At: {new Date(job.createdAt).toLocaleString()}</p>
                <h4>Sub Services:</h4>
                <ul>
                  {job.subServices && job.subServices.length > 0 ? (
                    job.subServices.map(subService => (
                      <li key={subService.id}>{subService.name}</li>
                    ))
                  ) : (
                    <li>No sub services for this job</li>
                  )}
                </ul>
                <button className="uat-delete-job-button" onClick={() => handleDeleteJob(job.id)}>Delete Job</button>
              </div>
            ))}
          </div>
        ) : (
          <p>No jobs for this user</p>
        )}
        <button onClick={handleCloseModal}>Close</button>
      </div>
    </div>
  );

  return (
    <div className="uat-user-admin-table-container">
      <h2>Active Users and Admins</h2>
      
      <div className="uat-button-container">
        <button onClick={() => setActiveTab('clients')} className={activeTab === 'clients' ? 'uat-active' : ''}>Clients</button>
        <button onClick={() => setActiveTab('admins')} className={activeTab === 'admins' ? 'uat-active' : ''}>Admins</button>
      </div>

      {activeTab === 'clients' && (
        <>
          <h3>Active Clients</h3>
          {renderTable(activeClients, clientColumns)}
        </>
      )}
      {activeTab === 'admins' && (
        <>
          <h3>Admins</h3>
          {renderTable(admins, adminColumns)}
        </>
      )}

      {showModal && renderJobsModal()}
    </div>
  );
};

export default UserAdminTable;