import React, { useState, useRef, useEffect } from 'react';
import './JewelryEditingSlider.css';

const JewelryEditingSlider = ({name, subServices}) => {
    
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const [currentSetIndex, setCurrentSetIndex] = useState(0);
  const containerRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    handleMouseMove(e);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    // Removed: setSliderPosition(50);
  };

  const handleMouseMove = (e) => {
    if (isDragging && containerRef.current) {
      e.preventDefault();
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    setIsDragging(true);
    handleTouchMove(e);
  };

  const handleTouchMove = (e) => {
    if (isDragging && containerRef.current) {
      const touch = e.touches[0];
      const rect = containerRef.current.getBoundingClientRect();
      const x = touch.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.min(Math.max(newPosition, 0), 100));
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    // Removed: setSliderPosition(50);
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchend', handleTouchEnd);
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchend', handleTouchEnd);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isDragging]);

  const handleThumbnailClick = (index) => {
    setCurrentSetIndex(index);
  };

  const handleNavigation = (direction) => {
    if (direction === 'prev') {
      setCurrentSetIndex((prevIndex) => 
        prevIndex > 0 ? prevIndex - 1 : subServices.length - 1
      );
    } else if (direction === 'next') {
      setCurrentSetIndex((prevIndex) => 
        prevIndex < subServices.length - 1 ? prevIndex + 1 : 0
      );
    }
  };

  const currentSet = subServices[currentSetIndex] || {};

  // If there are no subServices, render a message instead
  if (subServices.length === 0) {
    return <div>No jewelry images available</div>;
  }

  return (
    <div className="jes_container">
      <h2 className="jes_title">{name}</h2>
      <div 
        className="jes_slider-container" 
        ref={containerRef}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
      >
        <img src={`http://15.206.148.121:8080/${currentSet.afterImage || ''}`} alt="Before" className="jes_image jes_before-image" />
        <img 
          src={`http://15.206.148.121:8080/${currentSet.beforeImage || ''}`} 
          alt="After" 
          className="jes_image jes_after-image" 
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        />
        <div 
          className="jes_slider" 
          style={{ left: `${sliderPosition}%` }}
        >
          <div className="jes_slider-circle">
            <div className="jes_arrow jes_arrow-left"></div>
            <div className="jes_arrow jes_arrow-right"></div>
          </div>
        </div>
        <span className={`jes_label jes_label-before ${sliderPosition <= 10 ? 'jes_hidden' : ''}`}>Before</span>
        <span className={`jes_label jes_label-after ${sliderPosition >= 90 ? 'jes_hidden' : ''}`}>After</span>
      </div>
      <div className="jes_thumbnails">
        {subServices.map((set, index) => (
          <img
            key={set.id || index}
            src={`http://15.206.148.121:8080/${set.afterImage || ''}`}
            alt={`Thumbnail ${index + 1}`}
            className={`jes_thumbnail ${index === currentSetIndex ? 'jes_active' : ''}`}
            onClick={() => handleThumbnailClick(index)}
          />
        ))}
      </div>
      <div className="jes_navigation">
        <button className="jes_nav-button jes_prev" onClick={() => handleNavigation('prev')}>&lt;</button>
        <button className="jes_nav-button jes_next" onClick={() => handleNavigation('next')}>&gt;</button>
      </div>
    </div>
  );
};

export default JewelryEditingSlider;