// ServiceQuote.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Header/Navbar';
import Footer from '../Footer/Footer';
import { useCurrency } from '../../CurrencyContext';
import './ServiceQuotes.css'

const ServiceQuote = () => {
  const { serviceId } = useParams();
  const [service, setService] = useState(null);
  const [subServices, setSubServices] = useState([]);
  const [selectedSubServices, setSelectedSubServices] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { currency } = useCurrency();
  const [dropboxLink, setDropboxLink] = useState('');
  const [instructions, setInstructions] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [numberOfPhotos, setNumberOfPhotos] = useState(1);
  const [linkType, setLinkType] = useState('own');
  const navigate = useNavigate();

  const accessToken = 'sl.B7zUqkec6j76W1YXuCOD5u0uyr-i68F1PXTSWCMv5y40jOgomH7qSo9SBukj62FpnYonsgDVa13rSwCBkJfGBOybA9R9NXMBIC4bhk3mtF8QSkKOKtVe6d60_JdAomIVsSuYbFl-d8n0f1Ap313PhAM';

  useEffect(() => {
    const fetchServiceAndSubServices = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log('Fetching service with ID:', serviceId);
        const serviceResponse = await axios.get(`http://15.206.148.121:8080/api/services/${serviceId}`);
        console.log('Service response:', serviceResponse.data);
        setService(serviceResponse.data.service);

        console.log('Fetching subservices for serviceId:', serviceId);
        const subServicesResponse = await axios.get(`http://15.206.148.121:8080/api/subservices?serviceId=${serviceId}`);
        console.log('Subservices response:', subServicesResponse.data);
        
        if (Array.isArray(subServicesResponse.data.subServices)) {
          const filteredSubServices = subServicesResponse.data.subServices.filter(
            subService => subService.serviceId === parseInt(serviceId)
          );
          
          console.log(`Filtered ${filteredSubServices.length} subservices for serviceId ${serviceId}`);
          setSubServices(filteredSubServices);
          
          if (filteredSubServices.length !== subServicesResponse.data.subServices.length) {
            console.warn(`Note: Received ${subServicesResponse.data.subServices.length} subservices, but only ${filteredSubServices.length} match serviceId ${serviceId}`);
          }
        } else {
          console.error('Subservices data is not an array:', subServicesResponse.data);
          setError('Invalid subservices data received');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch service and subservices: ' + (err.response?.data?.message || err.message));
      } finally {
        setLoading(false);
      }
    };

    fetchServiceAndSubServices();
  }, [serviceId]);

  useEffect(() => {
    calculateTotalPrice();
  }, [selectedSubServices, currency]);

  const handleSubServiceChange = (subServiceId) => {
    setSelectedSubServices((prev) =>
      prev.includes(subServiceId)
        ? prev.filter((id) => id !== subServiceId)
        : [...prev, subServiceId]
    );
  };

  const calculateTotalPrice = () => {
    let total = 0;
    selectedSubServices.forEach((subServiceId) => {
      const subService = subServices.find((ss) => ss.id === subServiceId);
      if (subService) {
        const price = subService.prices.find((p) => p.currency === currency);
        if (price) {
          total += Number(price.price);
        }
      }
    });
    setTotalPrice(total);
  };

  const generateDropboxLink = async () => {
    try {
      const response = await axios.get('http://15.206.148.121:8080/api/get-upload-link');
      
      if (response.data && response.data.uploadurl) {
        setDropboxLink(response.data.uploadurl.url);
      } else {
        throw new Error('Upload URL not found in response');
      }
    } catch (error) {
      console.error('Error getting upload link:', error);
      setError('An error occurred while getting the upload link. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      console.log('Retrieved token:', token);

      if (!token) {
        console.log('No token found, redirecting to signin');
        navigate('/signin');
        return;
      }

      const config = {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'application/json',
          'Accept': '*/*'
        }
      };

      console.log('Request config:', config);

      const data = {
        serviceId,
        selectedSubServices,
        currency,
        dropboxLink,
        numberOfPhotos,
        instructions,
      };

      console.log('Request data:', data);

      const response = await axios.post('http://15.206.148.121:8080/api/jobs', data, config);

      console.log('Response:', response.data);

      alert('Job created successfully!');
      
      navigate(`/payment/${response.data.job.id}`);
    } catch (err) {
      console.error('Error details:', err);

      if (err.response) {
        console.log('Error response:', err.response.data);
        console.log('Error status:', err.response.status);
        console.log('Error headers:', err.response.headers);

        if (err.response.status === 401) {
          alert('Authentication failed. Please sign in again.');
          navigate('/signin');
        } else {
          setError(`Error: ${err.response.status} - ${err.response.data.message || 'Unknown error'}`);
        }
      } else if (err.request) {
        console.log('Error request:', err.request);
        setError('No response received from the server. Please try again.');
      } else {
        console.log('Error message:', err.message);
        setError('An unexpected error occurred. Please try again.');
      }
    }
  };
  
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!service) return <div>No service data available</div>;

  return (
    <>
      <Navbar/>
      <div className="service-quote">
        <h2>{service.name} Quote</h2>
        <form onSubmit={handleSubmit}>
          <div className="subservices">
            <h3>Select Subservices:</h3>
            {subServices.length > 0 ? (
              <div className="subservices-grid">
                {subServices.map((subService) => (
                  <div key={subService.id} className="subservice-item">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedSubServices.includes(subService.id)}
                        onChange={() => handleSubServiceChange(subService.id)}
                      />
                      <div className="subservice-details">
                        <span className="subservice-name">{subService.name}</span>
                        <span className="subservice-price">
                          {subService.prices.find((p) => p.currency === currency)?.price} {currency}
                        </span>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            ) : (
              <p>No subservices available for this service.</p>
            )}
          </div>
          <div className="number-of-photos">
            <label>
              Number of Photos:
              <input
                type="number"
                min="1"
                value={numberOfPhotos}
                onChange={(e) => setNumberOfPhotos(Math.max(1, parseInt(e.target.value)))}
                required
              />
            </label>
          </div>
          <div className="dropbox-link">
            <label>
              Link Type:
              <select value={linkType} onChange={(e) => setLinkType(e.target.value)}>
                <option value="own">Provide my own link</option>
                <option value="generate">Generate Dropbox link</option>
              </select>
            </label>
            
            {linkType === 'own' ? (
              <label>
                Dropbox Link:
                <input
                  type="text"
                  value={dropboxLink}
                  onChange={(e) => setDropboxLink(e.target.value)}
                  required
                />
              </label>
            ) : (
              <div>
                <button type="button" onClick={generateDropboxLink}>Generate Dropbox Link</button>
                {dropboxLink && (
                  <p>Generated Link: <a href={dropboxLink} target="_blank" rel="noopener noreferrer">{dropboxLink}</a></p>
                )}
              </div>
            )}
          </div>
          <div className="instructions">
            <label>
              Instructions:
              <textarea
                value={instructions}
                onChange={(e) => setInstructions(e.target.value)}
              ></textarea>
            </label>
          </div>
          <div className="total-price">
            <h3>Total Price: {totalPrice} {currency}</h3>
          </div>
          <button className="tp-button" type="submit">Create Job</button>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default ServiceQuote;