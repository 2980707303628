// BeforeAfterSlider.js
import React, { useState, useRef, useEffect } from 'react';
import './BeforeAfterSlider.css';

const BeforeAfterSlider = ({ beforeImage, afterImage }) => {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const containerRef = useRef(null);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging && containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const newPosition = (x / rect.width) * 100;
      setSliderPosition(Math.max(0, Math.min(100, newPosition)));
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('mousedown', handleMouseDown);
      container.addEventListener('mouseup', handleMouseUp);
      container.addEventListener('mouseleave', handleMouseUp);
      container.addEventListener('mousemove', handleMouseMove);
      
      container.addEventListener('touchstart', handleMouseDown);
      container.addEventListener('touchend', handleMouseUp);
      container.addEventListener('touchcancel', handleMouseUp);
      container.addEventListener('touchmove', (e) => {
        e.preventDefault();
        const touch = e.touches[0];
        handleMouseMove(touch);
      });
    }

    return () => {
      if (container) {
        container.removeEventListener('mousedown', handleMouseDown);
        container.removeEventListener('mouseup', handleMouseUp);
        container.removeEventListener('mouseleave', handleMouseUp);
        container.removeEventListener('mousemove', handleMouseMove);
        
        container.removeEventListener('touchstart', handleMouseDown);
        container.removeEventListener('touchend', handleMouseUp);
        container.removeEventListener('touchcancel', handleMouseUp);
        container.removeEventListener('touchmove', handleMouseMove);
      }
    };
  }, [isDragging]);

  return (
    <div className="ba-slider__item">
      <div className="ba-slider__container" ref={containerRef}>
        <div className="ba-slider__before-image" style={{ backgroundImage: `url(http://15.206.148.121:8080/${beforeImage})` }} />
        <div
          className="ba-slider__after-image"
          style={{
            backgroundImage: `url(http://15.206.148.121:8080/${afterImage})`,
            clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`,
          }}
        />
        <div
          className="ba-slider__slider"
          style={{ left: `${sliderPosition}%` }}
        >
          <div className="ba-slider__button" />
        </div>
      </div>
    </div>
  );
};

export default BeforeAfterSlider;